import React, { Component } from 'react';
import styles from './ConnectionNote.module.css';
import Button from '../../Elements/Button';
import Note from '../../../Models/Note';
import Connection from '../../../Models/Connection';

class ConnectionNote extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();

        this.connectionId = props.match.params.connectionId;
        this.identifier = props.match.params.id;

        if (this.identifier === "new") {
            this.note = new Note();
        }

        this.textArea = React.createRef();

        this.state = {
            uid: null,
            noteUpdated: 0,
            isSaving: false,
            saveDisabled: true,
            isDeleting: false
        };
    }

    componentDidMount() {
        document.title = "Vanir • Note Detail";


        if (this.auth.currentUser) {
            const uid = this.auth.currentUser.uid;
            this.setState({uid: uid});

            if (this.identifier === "new") {
                this.textArea.current.focus();
                return;
            }
    
            Note.get(this.database, uid, this.connectionId, this.identifier)
                .then(note => {
    
                    if (note == null) {
                        return;
                    }
    
                    this.note = note;
                    this.setState({
                        noteUpdated: this.state.noteUpdated+1,
                        saveDisabled: note.name === ""
                    });

                    this.textResize();
                })
                .catch(err => {
                    console.error(err);
                });
        }

        Connection.get(this.database, this.auth.currentUser.uid, this.connectionId)
            .then(connection => {

                if (connection == null) {
                    return;
                }

                this.setState({
                    connectionName: connection.name
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    textChanged = (event) => {
        this.note.text = event.target.value;

        this.textResize();

        this.setState({
            noteUpdated: this.state.noteUpdated+1,
            saveDisabled: this.note.text === ""
        });
    }

    textResize = () => {
        const textArea = this.textArea.current;
        if (textArea == null) {
            return;
        }

        const offset = textArea.offsetHeight - textArea.clientHeight;
        textArea.style.height = 'auto';
        const height = textArea.scrollHeight + offset;
        textArea.style.height = `${Math.max(36, height)}px`;
    }

    saveNote = () => {
        const { history } = this.props;
        const { uid } = this.state;
        var note = this.note;
        if (uid == null || note == null) {
            return;
        }

        this.setState({
            isSaving: true
        });

        if (note.identifier == null) {
            note.create(this.database, uid, this.connectionId)
                .then(noteId => {

                    history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            note.save(this.database, uid, this.connectionId)
                .then(() => {
                    this.setState({
                        isSaving: false
                    });

                    history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    deleteNote = () => {
        const { history } = this.props;
        const { uid } = this.state;
        var note = this.note;
        if (uid == null || note == null) {
            return;
        }

        if (window.confirm("Are you sure you want to delete this note?")) { 
            
            this.setState({
                isDeleting: true
            });

            note.delete(this.database, uid, this.connectionId)
                .then(() => {
                    this.setState({
                        isDeleting: false
                    });

                    history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    renderContent() {
        const { isSaving, isDeleting, saveDisabled, connectionName } = this.state;
        const note = this.note;
        const title = connectionName != null && connectionName !== "" ? `${connectionName} • ` : '';

        if (note == null) {
            return <div className={styles.loading}>Loading...</div>
        } else {
            return (
                <div>
                    <div className={`pageTitle`}>{`${title}Note`}</div>
                    <div className={`card ${styles.note}`}>
                        <div className={styles.titleRow}>
                            <textarea rows="1" className={styles.title} ref={this.textArea} type="text" id="text" name="text" value={note.text} placeholder="Type your note here..." onChange={this.textChanged} />
                        </div>
                        <div className={styles.buttons}>
                            <Button title="Save" disabled={saveDisabled} onClick={this.saveNote} loading={isSaving} />
                            {note.identifier != null && (
                                <Button title="Delete" buttonStyle="whiteDestructive" onClick={this.deleteNote} loading={isDeleting} />
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={`baseContainer`}>
                {this.renderContent()}
            </div>
        )
    }
}

export default ConnectionNote
