import Note from '../Models/Note';
import DateExt from '../Extensions/DateExt';

class NotesListener {

    listener = null;
    uid = null;
    connectionId = null;
    loadedInitially = false;

    constructor(database, storage, connectionId, dataUpdated) {

        this.database = database;
        this.storage = storage;
        this.connectionId = connectionId;
        this.dataUpdated = dataUpdated;
    }

    attemptStartAuth(currentUser) {

        if (currentUser == null) {
            return;
        }

        const uid = currentUser.uid;
        this.attemptStart(uid);
    }

    attemptStart(uid) {

        if (this.listener != null) {
            return;
        }

        this.uid = uid;
        var query = this.database.collection(`users/${uid}/connections/${this.connectionId}/notes`).orderBy("posted", "desc");
        this.listener = query.onSnapshot((snapshot) => {
            var notes = [];
            snapshot.forEach(doc => {
                if (doc.exists === true) {

                    const note = new Note(doc.data(), doc.id);
                    notes.push(note);
                }
            });

            const sortedNotes = this.sortNotes(notes);

            this.loadedInitially = true;

            if (this.dataUpdated) {
                this.dataUpdated(sortedNotes);
            }
        });
    }

    sortNotes(notes) {
        const groupedNotes = notes.reduce((acc, value) => {

            const key = DateExt.stripTime(value.posted);

            if (key != null) {
                (acc[key] = acc[key] || []).push(value);
            }

            return acc;
        }, {});

        const keys = Object.keys(groupedNotes).map(key => {
            return new Date(key);
        })

        var sortedKeys = keys.sort((firstEl, secondEl) => {

            const firstTime = firstEl.getTime();
            const secondTime = secondEl.getTime();

            if (firstTime < secondTime) {
                return 1;
            } else if (firstTime > secondTime) {
                return -1;
            }
            return 0;
        });

        var sortedNotes = [];
        sortedKeys.forEach(key => {
            sortedNotes.push(
                {key: key, value: groupedNotes[key]}
            );
        });

        return sortedNotes;
    }

    stop() {
        if (this.listener == null) {
            return;
        }

        this.listener();
        this.listener = null;
        this.uid = null;
        this.connectionId = null;
    }

}

export default NotesListener
