import ImageDownloader from '../Managers/ImageDownloader';
import UUID from 'uuid/v4';

class Card {

    static Type = Object.freeze({
        business: "business",
        personal: "personal"
    });

    static ImageSide = Object.freeze({
        front: 0,
        back: 1
    });

    static limit = 1;

    constructor(document, identifier) {

        if (document == null) {
            document = {};
        }

        this.identifier = identifier;
        this.type = document.type || Card.Type.placeholder;
        this.connectionIdentifier = document.connectionIdentifier || "";
        this.cardImageFilenames = document.cardImageFilenames || ["", ""];
        this.favoriteItems = document.favoriteItems || {};
        this.emails = document.emails || [];
        this.phoneNumbers = document.phoneNumbers || [];
        this.companyName = document.companyName || "";
        this.companyTitle = document.companyTitle || "";
        this.websites = document.websites || [];
        this.usernames = document.usernames || [];
        this.addresses = document.addresses || [];

        if (document.created != null) {
            if (document.created.toDate != null) {
                this.created = document.created.toDate();
            } else {
                this.created = document.created;
            }
        } else {
            this.created = new Date();
        }
        
        if (document.updated != null) {
            if (document.updated.toDate != null) {
                this.updated = document.updated.toDate();
            } else {
                this.updated = document.updated;
            }
        } else {
            this.updated = this.created;
        }
    }

    document() {
        const date = new Date();

        this.cleanupEmptyDetails();

        return {
            type: this.type,
            connectionIdentifier: this.connectionIdentifier,
            cardImageFilenames: this.cardImageFilenames,
            favoriteItems: this.favoriteItems,
            emails: this.emails,
            phoneNumbers: this.phoneNumbers,
            companyName: this.companyName.trim(),
            companyTitle: this.companyTitle.trim(),
            websites: this.websites,
            usernames: this.usernames,
            addresses: this.addresses,
            created: this.created || date,
            updated: date
        };
    }

    firestoreDoc = () => {
        return this.document();
    }

    cleanupEmptyDetails = () => {

        this.emails = this.emails.filter((item) => {
            return item.value != null && item.value !== ""
        });
        this.phoneNumbers = this.phoneNumbers.filter((item) => {
            return item.value != null && item.value !== ""
        });
        this.websites = this.websites.filter((item) => {
            return item.value != null && item.value !== ""
        });
        this.usernames = this.usernames.filter((item) => {
            return item.value != null && item.value !== ""
        });
        this.addresses = this.addresses.filter((item) => {
            return item.value != null && item.value !== ""
        });
    }

    isNew = () => {
        return (
            (this.companyName == null || this.companyName === "") &&
            (this.companyTitle == null || this.companyTitle === "") &&
            (this.cardImageFilenames[0] == null || this.cardImageFilenames[0] === "") &&
            (this.cardImageFilenames[1] == null || this.cardImageFilenames[1] === "") &&
            (this.emails == null || this.emails.length === 0) &&
            (this.phoneNumbers == null || this.phoneNumbers.length === 0) &&
            (this.websites == null || this.websites.length === 0) &&
            (this.usernames == null || this.usernames.length === 0) &&
            (this.addresses == null || this.addresses.length === 0)
        );
    }

    nameFor = (side) => {
        if (side === Card.ImageSide.front) {
            return "cardFront";
        } else if (side === Card.ImageSide.back) {
            return "cardBack";
        } else {
            return null;
        }
    }

    loadCardImage = (storage, functions, currentUser, userId, connection, side, dataUpdated) => {

        const filename = this.cardImageFilenames[side];
        if (filename == null || filename === "") {
            return;
        }

        var path = null;
        if (currentUser != null && connection != null) {
            if (connection.type === "member") {
                path = `users/${connection.identifier}/myCards/${this.identifier}`;
            } else {
                path = `users/${currentUser.uid}/connections/${connection.identifier}/cards/${this.identifier}`;
            }
        } else if (userId != null) {
            path = `users/${userId}/myCards/${this.identifier}`;
        }

        if (path == null) {
            return;
        }

        const name = this.nameFor(side);
        if (name == null) {
            return;
        }

        const ref = storage.ref(`${path}/${filename}`);
        ImageDownloader(functions, filename, path, name, ref)
            .then(url => {
                dataUpdated({
                    [name]: url
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    static phoneNumbers(cards) {

        var allItems = [];
        var favoriteItems = [];
        var nonFavoriteItems = [];

        cards.forEach(card => {

            const allFavoriteItems = card.favoriteItems || {};
            const cardFavoriteItems = allFavoriteItems.phoneNumbers || [];

            const phoneNumbers = card.phoneNumbers || [];
            phoneNumbers.forEach(detail => {

                if (allItems.includes(detail.value) === false) {

                    detail.card = card;

                    if (cardFavoriteItems.includes(detail.value)) {
                        favoriteItems.push(detail)
                    } else {
                        nonFavoriteItems.push(detail)
                    }
                    
                    allItems.push(detail.value)
                }
            });
        });

        return {
            allItems: allItems,
            favoriteItems: favoriteItems,
            nonFavoriteItems: nonFavoriteItems
        };
    }

    static emails(cards) {
        var allItems = [];
        var favoriteItems = [];
        var nonFavoriteItems = [];

        cards.forEach(card => {

            const allFavoriteItems = card.favoriteItems || {};
            const cardFavoriteItems = allFavoriteItems.emails || [];
            
            const emails = card.emails || [];
            emails.forEach(detail => {

                if (allItems.includes(detail.value) === false) {

                    detail.card = card;

                    if (cardFavoriteItems.includes(detail.value)) {
                        favoriteItems.push(detail)
                    } else {
                        nonFavoriteItems.push(detail)
                    }
                    
                    allItems.push(detail.value)
                }
            });
        });

        return {
            allItems: allItems,
            favoriteItems: favoriteItems,
            nonFavoriteItems: nonFavoriteItems
        };
    }

    static create(database, connection, currentUser, card) {

        const uid = currentUser.uid;

        var path = null;
        if (connection != null && connection.type === 'placeholder') {
            path = `users/${uid}/connections/${connection.identifier}/cards`;
        } else {
            path = `users/${uid}/myCards`;
        }

        if (path == null) {
            return;
        }

        return database.collection(path)
            .add(card.firestoreDoc())
            .then((docRef) => {
                return docRef.id;
            });
    }

    create = (database, connection, currentUser) => {
        return Card.create(database, connection, currentUser, this);
    }

    static save(database, storage, connection, currentUser, card, cardImageFiles) {

        const identifier = card.identifier;
        const uid = currentUser.uid;

        var path = null;
        if (connection != null && connection.type === 'placeholder') {
            path = `users/${uid}/connections/${connection.identifier}/cards/${identifier}`;
        } else {
            path = `users/${uid}/myCards/${identifier}`;
        }

        if (path == null) {
            return;
        }

        const frontImageFile = cardImageFiles[0];
        const backImageFile = cardImageFiles[1];

        // Upload new image file and then save doc
        if (frontImageFile != null || backImageFile != null) {

            // Attempt to save the front image
            return Card.saveCardImage(storage, path, Card.ImageSide.front, frontImageFile)
                .then(cardImageFilename => {

                    // Set the saved image name
                    if (cardImageFilename != null) {
                        card.cardImageFilenames[0] = cardImageFilename;
                    }

                    // Attempt to save the back image
                    return Card.saveCardImage(storage, path, Card.ImageSide.back, backImageFile)
                        .then(cardImageFilename => {

                            // Set the saved image name
                            if (cardImageFilename != null) {
                                card.cardImageFilenames[1] = cardImageFilename;
                            }

                            // Save the doc
                            return database.doc(path)
                                .update(card.firestoreDoc());
                        });
                });
        }

        // No new image files to save, just save doc
        return database.doc(path)
            .update(card.firestoreDoc());
    }

    save = (database, storage, connection, currentUser, cardImageFiles) => {
        return Card.save(database, storage, connection, currentUser, this, cardImageFiles);
    }

    static saveCardImage(storage, path, side, cardImageFile) {

        if (cardImageFile == null) {
            return Promise.resolve(null);
        }

        const cardImageFilename = `card${side === Card.ImageSide.front ? 'Front' : 'Back'}_${UUID()}.jpeg`;

        const storageRef = storage.ref();
        return storageRef.child(`${path}/${cardImageFilename}`)
            .put(cardImageFile)
            .then(() => {
                return Promise.resolve(cardImageFilename);
            });
    }

    static deleteCardImage(database, storage, connection, currentUser, card, side) {

        const identifier = card.identifier;
        const uid = currentUser.uid;

        var path = null;
        if (connection != null && connection.type === 'placeholder') {
            path = `users/${uid}/connections/${connection.identifier}/cards/${identifier}`;
        } else {
            path = `users/${uid}/myCards/${identifier}`;
        }

        if (path == null) {
            return;
        }

        const filename = card.cardImageFilenames[side];
        if (filename == null || filename === "") {
            return Promise.resolve();
        }

        const storageRef = storage.ref();
        return storageRef.child(`${path}/${filename}`)
            .delete()
            .then(() => {
                // Set blank filename
                card.cardImageFilenames[side] = "";

                // Save doc
                return database.doc(path)
                    .update(card.firestoreDoc());
            });
    }

    deleteCardImage = (database, storage, connection, currentUser, side) => {
        return Card.deleteCardImage(database, storage, connection, currentUser, this, side);
    }

    static delete(database, identifier, connection, currentUser) {

        const uid = currentUser.uid;

        var path = null;
        if (connection != null && connection.type === 'placeholder') {
            path = `users/${uid}/connections/${connection.identifier}/cards/${identifier}`;
        } else {
            path = `users/${uid}/myCards/${identifier}`;
        }

        if (path == null) {
            return;
        }

        return database.doc(path)
            .delete();
    }

    delete = (database, connection, currentUser) => {
        return Card.delete(database, this.identifier, connection, currentUser);
    }

    static get(database, uid, connection, identifier) {

        var path = null;
        if (connection != null && connection.type === 'placeholder') {
            path = `users/${uid}/connections/${connection.identifier}/cards/${identifier}`;
        } else {
            path = `users/${uid}/myCards/${identifier}`;
        }

        if (path == null) {
            return;
        }

        return database.doc(path)
            .get()
            .then(snapshot => {
                if (snapshot.exists === true) {
                    return new Card(snapshot.data(), snapshot.id);
                }
            });
    }

}

export default Card
