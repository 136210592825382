import React from 'react';
import Member from './Member';
import ImageDownloader from '../Managers/ImageDownloader'
import UUID from 'uuid/v4';

class Connection {

    static Type = Object.freeze({
        placeholder: "placeholder",
        member: "member"
    });

    static SortType = Object.freeze({
        firstName: 0,
        lastName: 1,
        rating: 2,
        connectedOrCreated: 3,
        updated: 4
    })

    static Rating = Object.freeze({
        none: 0,
        normal: 1,
        important: 2,
        veryImportant: 3
    });

    static anonymousTag = "com.Frostlight.Vanir.connection.anonymous";
    static sugestedTag = "com.Frostlight.Vanir.connection.sugested";

    constructor(document, identifier) {

        if (document == null) {
            document = {};
        }

        this.identifier = identifier;
        this.type = document.type || Connection.Type.placeholder;
        this.firstName = document.firstName || "";
        this.lastName = document.lastName || "";
        if ((this.firstName != null && this.firstName !== "") && (this.lastName != null && this.lastName !== "")) {
            this.name = `${this.firstName} ${this.lastName}`;
        } else if ((this.firstName != null && this.firstName !== "") && (this.lastName == null || this.lastName === "")) {
            this.name = this.firstName;
        } else if ((this.firstName == null || this.firstName === "") && (this.lastName != null && this.lastName !== "")) {
            this.name = this.lastName;
        } else {
            this.name = "";
        }
        this.userImageFilename = document.userImageFilename || "";
        this.aboutMe = document.aboutMe || "";
        this.rating = document.rating || 0;
        this.tags = document.tags || [];
        this.globalTags = document.globalTags || [];
        this.cardsCount = document.cardsCount || 0;
        this.notesCount = document.notesCount || 0;
        this.tasksCount = document.tasksCount || 0;
        if (document.connected != null) {
            this.connected = document.connected.toDate();
        } else {
            this.connected = null;
        }
        this.anonymous = document.anonymous || false;
        this.exportedContactIdentifier = document.exportedContactIdentifier;

        if (document.created != null) {
            this.created = document.created.toDate();
        } else {
            this.created = new Date();
        }
        
        if (document.updated != null) {
            this.updated = document.updated.toDate();
        } else {
            this.updated = this.created;
        }
    }

    document() {
        const date = new Date();
        return {
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            aboutMe: this.aboutMe.trim(),
            userImageFilename: this.userImageFilename,
            rating: this.rating,
            tags: this.tags,
            globalTags: this.globalTags,
            type: this.type,
            exportedContactIdentifier: this.exportedContactIdentifier || null,
            connected: this.connected || null,
            created: this.created || date,
            updated: date
        };
    }

    firestoreDoc = () => {
        return this.document();
    }

    isNew = () => {
        return (
            (this.firstName == null || this.firstName === "") &&
            (this.lastName == null || this.lastName === "") &&
            (this.userImageFilename == null || this.userImageFilename === "")
        );
    }

    loadUserImage = (storage, functions, currentUser, dataUpdated) => {
        
        if (this.userImageFilename == null || this.userImageFilename === "") {
            return;
        }

        var path = null;
        if (currentUser != null && this.type === "placeholder") {
            const uid = currentUser.uid;
            path = `users/${uid}/connections/${this.identifier}`;
        } else {
            path = `users/${this.identifier}`;
        }

        if (path == null) {
            return;
        }

        const ref = storage.ref(`${path}/${this.userImageFilename}`);
        ImageDownloader(functions, this.userImageFilename, path, "userImage", ref)
            .then(url => {
                dataUpdated(url);
            })
            .catch(err => {
                console.error(err);
            });
    }

    renderName = (sortType) => {

        if (sortType === Connection.SortType.firstName) {
            return (<div><strong>{this.firstName}</strong> {this.lastName}</div>)
        } else {
            return (<div>{this.firstName}<strong> {this.lastName}</strong></div>)
        }
    }

    static create(database, currentUser, connection) {

        var path = null;
        if (currentUser != null && connection.type === "placeholder") {
            const uid = currentUser.uid;
            path = `users/${uid}/connections`;
        } else {
            console.error(`Members can't be created!`);
            return;
        }

        if (path == null) {
            return;
        }

        return database.collection(path)
            .add(connection.firestoreDoc())
            .then((docRef) => {
                return docRef.id;
            });
    }

    create = (database, currentUser) => {
        return Connection.create(database, currentUser, this);
    }

    static save(database, storage, currentUser, connection, userImageFile) {

        const identifier = connection.identifier;
        var path = null;
        if (currentUser != null && connection.type === "placeholder") {
            const uid = currentUser.uid;
            path = `users/${uid}/connections/${identifier}`;
        } else {
            path = `users/${identifier}`;
        }

        if (path == null) {
            return;
        }

        // Upload new image file and then save doc
        if (userImageFile != null) {

            const userImageFilename = `userImage_${UUID()}.jpeg`;

            const storageRef = storage.ref();
            return storageRef.child(`${path}/${userImageFilename}`)
                .put(userImageFile)
                .then(() => {
                    // Set filename
                    connection.userImageFilename = userImageFilename;

                    // Save doc
                    return database.doc(path)
                        .update(connection.firestoreDoc());
                });
        }

        // No new image file to save, just save doc
        return database.doc(path)
            .update(connection.firestoreDoc());
    }

    save = (database, storage, currentUser, userImageFile) => {
        return Connection.save(database, storage, currentUser, this, userImageFile);
    }

    static deleteUserImage(database, storage, currentUser, connection) {

        const identifier = connection.identifier;
        var path = null;
        if (currentUser != null && connection.type === "placeholder") {
            const uid = currentUser.uid;
            path = `users/${uid}/connections/${identifier}`;
        } else {
            path = `users/${identifier}`;
        }

        if (path == null) {
            return;
        }
        
        const storageRef = storage.ref();
        return storageRef.child(`${path}/${connection.userImageFilename}`)
            .delete()
            .then(() => {
                // Set blank filename
                connection.userImageFilename = "";

                // Save doc
                return database.doc(path)
                    .update(connection.firestoreDoc());
            });
    }

    deleteUserImage = (database, storage, currentUser) => {
        return Connection.deleteUserImage(database, storage, currentUser, this);
    }

    static delete(database, identifier, currentUser, connection) {

        var path = null;
        if (currentUser != null && connection.type === "placeholder") {
            const uid = currentUser.uid;
            path = `users/${uid}/connections/${identifier}`;
        } else {
            console.error(`Members can't be deleted!`);
        }

        if (path == null) {
            return;
        }

        return database.doc(path)
            .delete();
    }

    delete = (database, currentUser) => {
        return Connection.delete(database, this.identifier, currentUser, this);
    }

    static get(database, uid, identifier) {
        return database.doc(`users/${uid}/connections/${identifier}`)
            .get()
            .then(snapshot => {
                if (snapshot.exists === true) {

                    if (snapshot.get("type") === "member") {
                        console.log('Member');
                        return new Member(snapshot.data(), snapshot.id);
                    } else {
                        console.log('Placeholder');
                        return new Connection(snapshot.data(), snapshot.id);
                    }
                }
            });
    }

}

export default Connection
