import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import styles from './Footer.module.css';

class Footer extends Component {
    render() {
        return (
            <footer>
                <p className={styles.copyright}>
                    © 2018 Frostlight Solutions AB<br />
                    <Link to='/terms'>Terms & Conditions</Link> - <Link to='/privacy'>Privacy Policy</Link>
                </p>
            </footer>
        );
    }
}

export default Footer;
