import React, { Component } from 'react';
import Header from './Component/Navigation/Header';
import AppRouter from './Component/Routing/AppRouter';
import Footer from './Component/Navigation/Footer';
import Member from './Models/Member';
import './App.css';

const firebase = require("firebase/app");
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");

class App extends Component {
    constructor(props) {
        super(props);

        var config = {
            apiKey: "AIzaSyDG-9Y5QEoyoBIho03BUiYlA4v1JSZc_po",
            authDomain: "vanir-6fa7b.firebaseapp.com",
            databaseURL: "https://vanir-6fa7b.firebaseio.com",
            projectId: "vanir-6fa7b",
            storageBucket: "vanir-6fa7b.appspot.com",
            messagingSenderId: "1010082434456"
        };
        firebase.initializeApp(config);

        this.auth = firebase.auth();
        this.database = firebase.firestore();

        this.state = {
            authFetched: false,
            isLoggedIn: false
        };
    }

    componentDidMount() {

        this.auth.onAuthStateChanged(user => {

            if (user != null) {

                Member.get(this.database, user.uid)
                    .then(member => {

                        if (member.isAdmin === true) {

                            this.setState({
                                authFetched: true,
                                isLoggedIn: true
                            });
                
                        } else {

                            window.alert(`You are not autherised to login to Vanir via the web!`);

                            this.setState({
                                authFetched: false,
                                isLoggedIn: false
                            });

                            this.auth.signOut().catch((error) => {
                                console.error(error);
                            });
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });

            } else {
                localStorage.removeItem('connectionSortType');

                this.setState({
                    authFetched: true,
                    isLoggedIn: false
                });
            }
        });
    }

    render() {
        const { isLoggedIn, authFetched } = this.state;

        return (
            <div className="App">
                <Header firebase={firebase} isLoggedIn={isLoggedIn} authFetched={authFetched} />
                <AppRouter firebase={firebase} isLoggedIn={isLoggedIn} authFetched={authFetched} />
                <Footer />
            </div>
        );
    }
}

export default App;
