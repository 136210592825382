import React, { Component } from 'react';
import styles from './ConnectionOverview.module.css';
import sharedStyles from '../Shared/ProfileShared.module.css';
import ConnectionListener from '../../../Managers/Connections/ConnectionListener';
import Avatar from '../../Elements/Avatar';
import CardsContainer from '../../Views/Cards/CardsContainer';
import Button from '../../Elements/Button';
import QuickButton from '../../Elements/QuickButton';
import Detail from '../../Elements/Detail';
import ModalCardView from '../../Elements/ModalCardView';
import Card from '../../../Models/Card';
import QuickItemView from './QuickItemView';
import ShareButton from '../../Elements/ShareButton';
import InputDetail from '../../Elements/InputDetail';
import CardsListener from '../../../Managers/CardsListener';
import AddButton from '../../Elements/AddButton';

class ConnectionOverview extends Component {

    phoneNumbers = [];
    emails = [];

    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.identifier = props.match.params.id;

        this.connectionListener = new ConnectionListener(this.database, this.storage, this.identifier, (connection) => {

            if (connection.userImageFilename == null || connection.userImageFilename === "") {
                this.setState({userImageUrl: null});
            }

            connection.loadUserImage(this.storage, this.functions, this.auth.currentUser, url => {
                this.setState({userImageUrl: url});
            });

            if (connection != null) {
                document.title = `Vanir • ${connection.name}`
            }

            this.firstName = connection.firstName || "";
            this.lastName = connection.lastName || "";
            this.userImageFile = null;

            this.setState({
                connection: connection,
                editing: connection.isNew()
            });
        });

        this.state = {
            connection: null,
            userImageUrl: null,
            exportingContact: false,
            tasksCount: null,
            shouldShowQuickView: false,
            quickViewType: null,
            quickButtonPhoneEnabled: false,
            quickButtonMessageEnabled: false,
            quickButtonEmailEnabled: false,
            quickButtonChatEnabled: false,
            editing: false,
            placeholderEdited: 0,
            isSaving: false,
            isDeleting: false
        };
    }

    componentDidMount() {
        document.title = "Vanir";

        this.connectionListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.connectionListener.stop();
    }

    cardsUpdated = (cards) => {
        const { connection } = this.state;
        if (connection == null) {
            return;
        }

        this.phoneNumbers = Card.phoneNumbers(cards, connection);
        this.emails = Card.emails(cards, connection);

        this.setState({
            quickButtonPhoneEnabled: this.phoneNumbers.allItems.length > 0,
            quickButtonMessageEnabled: this.phoneNumbers.allItems.length > 0,
            quickButtonEmailEnabled: this.emails.allItems.length > 0
        });
    }

    quickButtonPhone = () => {

        if (this.phoneNumbers.allItems.length === 1) {
            window.open(`tel:${this.phoneNumbers.allItems[0]}`);
        } else {

            this.setState({
                shouldShowQuickView: true,
                quickViewType: "phone"
            });
        }
    }

    quickButtonMessage = () => {

        if (this.phoneNumbers.allItems.length === 1) {
            window.open(`sms:${this.phoneNumbers.allItems[0]}`);
        } else {

            this.setState({
                shouldShowQuickView: true,
                quickViewType: "message"
            });
        }
    }

    quickButtonEmail = () => {


        if (this.emails.allItems.length === 1) {
            window.open(`mailto:${this.emails.allItems[0]}`);
        } else {

            this.setState({
                shouldShowQuickView: true,
                quickViewType: "email"
            });
        }
    }

    quickButtonChat = () => {
        // TODO: Open chat when implemented
    }

    closeQuickView = () => {
        this.setState({
            shouldShowQuickView: false,
            quickViewType: null
        });
    }

    shareButtonClicked = () => {
        const { history } = this.props;

        history.push(`/connections/${this.identifier}/share`)
    }

    openTasks = () => {
        const { history } = this.props;

        history.push(`/connections/${this.identifier}/tasks`);
    }

    openNotes = () => {
        const { history } = this.props;

        history.push(`/connections/${this.identifier}/notes`);
    }

    toggleEdit = () => {
        const { editing } = this.state;

        if (editing === false) { // So will be true after state update
            this.connectionListener.stop();
        }

        this.setState({editing: !editing})
    }

    firstNameChanged = (event) => {
        const { placeholderEdited } = this.state;

        this.firstName = event.target.value;

        this.setState({placeholderEdited: placeholderEdited+1});
    }

    lastNameChanged = (event) => {
        const { placeholderEdited } = this.state;

        this.lastName = event.target.value;

        this.setState({placeholderEdited: placeholderEdited+1});
    }

    imageInputDidChange = () => {
        const input = document.querySelector('#userImage');

        if (input == null || input.files.length === 0) {
            return;
        }

        const file = input.files[0];
        this.userImageFile = file;
        const url = window.URL.createObjectURL(file);
        this.setState({userImageUrl: url});
    }

    deleteUserImage = () => {

        if (window.confirm("Are you sure you want to delete your user image?")) { 

            const { connection } = this.state;

            connection.deleteUserImage(this.database, this.storage, this.auth.currentUser)
                .then(() => {
                    const input = document.querySelector('input');
                    input.value = null;

                    this.userImageFile = null;
                    this.setState({userImageUrl: null});
                })
                .catch(err => {
                   console.error(err);
                });
        }
    }

    cancelEdit = () => {
        this.setState({editing: false});

        this.connectionListener.attemptStartAuth(this.auth.currentUser);
    }

    saveProfile = () => {
        const { connection } = this.state;

        connection.firstName = this.firstName;
        connection.lastName = this.lastName;

        this.setState({isSaving: true});

        connection.save(this.database, this.storage, this.auth.currentUser, this.userImageFile)
            .then(() => {

                this.userImageFile = null;
                this.setState({editing: false, isSaving: false})
                this.connectionListener.attemptStartAuth(this.auth.currentUser);
            })
            .catch(err => {
                console.error(err);
            });
    }

    deletePlaceholder = () => {
        const { history } = this.props;
        const { connection } = this.state;

        if (window.confirm("Are you sure you want to delete this placeholder?")) { 
            
            this.setState({
                isDeleting: true
            });

            connection.delete(this.database, this.auth.currentUser)
                .then(() => {
                    this.setState({
                        isDeleting: false
                    });

                    history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    exportContact = () => {
        const { connection, exportingContact } = this.state;

        if (connection.identifier == null || connection.identifier === "" || connection.name == null || connection.name === "") {
            return
        }

        if (exportingContact === true) {
            return
        }

        this.setState({
            exportingContact: true
        });

        const createvCard = this.functions.httpsCallable("createvCard");
        createvCard({memberId: connection.identifier})
            .then((result) => {

                // Revoke any previously created urls
                if (this.vCardUrl != null) {
                    window.URL.revokeObjectURL(this.vCardUrl);
                }

                // Get the vCard string
                const vCard = result.data.vCard;

                // Create a Blob from the vCard
                const contentType = "text/vcard";
                var blob = new Blob([vCard], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                
                // Make the url the current url
                window.location.href = url;

                this.setState({
                    exportingContact: false
                });

                this.vCardUrl = url
            })
            .catch(err => {
                console.error(err);
            });
    }

    openInApp = () => {

        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS === false) {
            return
        }

        var url = document.URL;
        if (url.startsWith("https://")) {
            url = url.replace("https://", "vanirapp://");
        } else if (url.startsWith("http://")) {
            url = url.replace("http://", "vanirapp://");
        }
        window.open(url);
    }

    createCard = () => {
        const { connection } = this.state;
        const currentUser = this.auth.currentUser;

        if (currentUser == null || connection == null) {
            return;
        }

        CardsListener.numberOfCards(this.database, CardsListener.Type.connection, currentUser.uid, connection.identifier)
            .then(cardsCount => {

                if (cardsCount >= Card.limit) {
                
                    alert('Card Limit Reached\n\nYou have reached the current card limit. This limit may change in later versions.');
                    return
                }

                const card = new Card({
                    type: 'business',
                    connectionIdentifier: connection.identifier
                });
                card.create(this.database, connection, currentUser)
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    }

    renderDetail() {
        const { connection, editing, isSaving, isDeleting } = this.state;

        if (editing === true) {

            return (
                <div>
                    <InputDetail title="First Name" icon="/img/grey-first-name.svg" type="text" name="firstname" placeholder="First Name" value={this.firstName} autofocus="true" onChange={this.firstNameChanged} />
                    <InputDetail title="Last Name" icon="/img/grey-last-name.svg" type="text" name="lastname" placeholder="Last Name" value={this.lastName} autofocus="false" onChange={this.lastNameChanged} />
                    <div className={sharedStyles.buttons}>
                        <Button className={sharedStyles.button} buttonStyle="whiteDefault" title="Cancel" disabled={isSaving} onClick={this.cancelEdit} />
                        <Button className={sharedStyles.button} buttonStyle="whiteDestructive" title="Delete" onClick={this.deletePlaceholder} loading={isDeleting} />
                        <Button className={sharedStyles.button} title="Save" onClick={this.saveProfile} loading={isSaving} />
                    </div>
                </div>
            )

        } else {
            const tasksCount = connection != null && connection.tasksCount !== 0 ? connection.tasksCount : null;
            const notesCount = connection != null && connection.notesCount !== 0 ? connection.notesCount : null;
            const aboutMe = connection.aboutMe || "";

            return (
                <div>
                    <div className={styles.options}>
                        <Detail value={"Tasks"} icon="/img/grey-tasks.svg" accessoryIcon={"/img/grey-disclosure-indicator.svg"} badge={tasksCount} style={styles.optionRow} onClick={this.openTasks} />
                        <Detail value={"Notes"} icon="/img/grey-notes.svg" accessoryIcon={"/img/grey-disclosure-indicator.svg"} badge={notesCount} style={styles.optionRow} onClick={this.openNotes} hideSeperator={connection.aboutMe === ""} />
                        {/* <Detail value={"Details"} icon="/img/grey-details.svg" accessoryIcon={"/img/grey-disclosure-indicator.svg"} style={styles.optionRow} /> */}
                    </div>
                    {aboutMe !== "" && (
                        <div className={sharedStyles.aboutMe}>
                            <div className={sharedStyles.aboutMeTitle}>About Me</div>
                            <div className={sharedStyles.aboutMeText}>{aboutMe}</div>
                        </div>
                    )}
                </div>
            )
        }
    }

    render() {
        const { connection, userImageUrl, exportingContact, shouldShowQuickView, quickViewType, quickButtonPhoneEnabled, quickButtonMessageEnabled, quickButtonEmailEnabled, quickButtonChatEnabled, editing } = this.state;

        var quickViewItems = [];
        var prefix = null;
        if (quickViewType === "phone") {
            quickViewItems = this.phoneNumbers;
            prefix = 'tel:';
        } else if (quickViewType === "message") {
            quickViewItems = this.phoneNumbers;
            prefix = 'sms:';
        } else if (quickViewType === "email") {
            quickViewItems = this.emails;
            prefix = 'mailto:';
        }

        var buttons = [];
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS === true) {
            buttons = [
                <Button key="button1" title="Export Contact" onClick={this.exportContact} loading={exportingContact} />,
                <Button key="button2" title="Open in App" onClick={this.openInApp} />
            ];
        } else {
            if (connection != null && connection.isNew() === false) {
                buttons.push(<Button key="button1" title="Export Contact" onClick={this.exportContact} loading={exportingContact} />);
            }
        }

        if (connection == null) {
            return <div className={`pageTitle`}>Loading...</div>
        }

        var avitarActionWord = "Click";
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            avitarActionWord = "Tap";
        }

        return (
            <div>
                {shouldShowQuickView === true && (
                    <ModalCardView component={QuickItemView} visible={shouldShowQuickView} items={quickViewItems} prefix={prefix} close={this.closeQuickView} />
                )}
                <div className={sharedStyles.profile}>
                    {connection.type === 'placeholder' ? (
                        <div className={`pageTitle ${sharedStyles.titleContainer}`}>
                            <div className={`${sharedStyles.title} ${editing === false && sharedStyles.titleEdit}`}>{editing ? "Editing" : connection.name}</div>
                            {editing === false && (
                                <button className={sharedStyles.edit} onClick={this.toggleEdit} />
                            )}
                        </div>
                    ) : (
                        <div className={`pageTitle`}>{connection.name}</div>
                    )}
                    <div className={`${sharedStyles.card} ${sharedStyles.cardFull}`}>
                        {connection.type === 'member' && (
                            <div className={sharedStyles.share}>
                                <ShareButton memberType={connection.type} onClick={this.shareButtonClicked} />
                            </div>
                        )}
                        <Avatar imageUrl={userImageUrl} style={{ width: 116, height: 116, marginTop: -69 }} />
                        {editing === true && (
                            <div className={sharedStyles.avitarEditing} onClick={this.selectUserImage}>
                                <label>{avitarActionWord} to<br/>Add Image</label>
                                <input className={sharedStyles.avitarEditing} type="file" id="userImage" name="userImage" accept="image/jpg, image/jpeg" onChange={this.imageInputDidChange} />
                                {connection.userImageFilename !== "" && (
                                    <div className={sharedStyles.avitarClear} onClick={this.deleteUserImage} />
                                )}
                            </div>
                        )}
                        <div className={styles.quickButtons}>
                            <QuickButton icon={'/img/white-phone.svg'} onClick={this.quickButtonPhone} disabled={!quickButtonPhoneEnabled} />
                            <QuickButton icon={'/img/white-message.svg'} onClick={this.quickButtonMessage} disabled={!quickButtonMessageEnabled} />
                            <QuickButton icon={'/img/white-email.svg'} onClick={this.quickButtonEmail} disabled={!quickButtonEmailEnabled} />
                            <QuickButton icon={'/img/white-chat.svg'} onClick={this.quickButtonChat} disabled={!quickButtonChatEnabled} />
                        </div>
                        {this.renderDetail()}
                    </div>
                    <CardsContainer firebase={this.props.firebase} connection={connection} showFavorites={true} dataUpdated={this.cardsUpdated} editable={connection.type === 'placeholder'} />
                    <div className={sharedStyles.buttons}>{buttons}</div>
                </div>
                <AddButton className={`addButton`} onClick={this.createCard} />
            </div>
        );
    }
}

export default ConnectionOverview
