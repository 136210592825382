import React, { Component } from 'react';
import styles from './Tasks.module.css';
import TasksListener from '../../../Managers/TasksListener';
import DateExt from '../../../Extensions/DateExt';
import TaskRow from '../../Views/Tasks/TaskRow';
import SelectorBar from '../../Elements/SelectorBar';
import AddButton from '../../Elements/AddButton';
import EmptyDataView from '../../Elements/EmptyDataView';
import Connection from '../../../Models/Connection';

class Tasks extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.identifier = props.match.params.id;

        var completeState = "incomplete";
        var subsection = "Due";

        const pathComps = props.history.location.pathname.split('/');
        const finalPath = pathComps.pop();
        if (finalPath === "list") {
            subsection = "List";
        } else if (finalPath === "completed") {
            completeState = "completed";

            const secondLastPath = pathComps.pop(2);
            if (secondLastPath === "list") {
                subsection = "List";
            }
        }
        
        this.tasksListener = new TasksListener(this.database, this.storage, this.identifier, `${completeState}${subsection}`, (tasks) => {
            this.setState({
                tasks: tasks
            });
        });

        this.state = {
            tasks: [],
            showComplete: completeState === "completed",
            completeState: completeState,
            subsection: subsection
        };
    }

    componentDidMount() {
        document.title = "Vanir • Tasks";

        this.tasksListener.attemptStartAuth(this.auth.currentUser);

        Connection.get(this.database, this.auth.currentUser.uid, this.identifier)
            .then(connection => {

                if (connection == null) {
                    return;
                }

                this.setState({
                    connectionName: connection.name
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    componentWillUnmount() {
        this.tasksListener.stop();
    }

    selectorBarChanged = (index) => {
        const { completeState } = this.state;
        var newSubsection;

        if (index === 0) {
            newSubsection = "Due";
        } else if (index === 1) {
            newSubsection = "List";
        }

        this.setState({
            subsection: newSubsection
        });

        this.tasksListener.setSortType(`${completeState}${newSubsection}`);
        
        const basePath = this.identifier != null && this.identifier !== "" ? `/connections/${this.identifier}/tasks` : '/tasks';
        var path = `${basePath}/${newSubsection === "Due" ? "scheduled" : "list"}`;
        if (completeState === "completed") {
            path += '/completed';
        }
        window.history.pushState(null, null, path);
    }

    toggleCompleteState = () => {
        const { subsection, showComplete } = this.state;
        var newState;

        if (showComplete === false) {
            newState = "completed";
        } else {
            newState = "incomplete";
        }

        this.setState({
            completeState: newState,
            showComplete: !showComplete
        });

        this.tasksListener.setSortType(`${newState}${subsection}`);
        
        const basePath = this.identifier != null && this.identifier !== "" ? `/connections/${this.identifier}/tasks` : '/tasks';
        var path = `${basePath}/${subsection === "Due" ? "scheduled" : "list"}`;
        if (newState === "completed") {
            path += `/completed`
        }
        window.history.pushState(null, null, path);
    }

    createTask = () => {
        const { history } = this.props;
        const { subsection } = this.state;

        history.push('/tasks/new', {
            subsection: subsection,
            connectionId: this.identifier
        });
    }

    renderSection(group) {

        const date = group.key;

        var overdue = false;
        var headerText;
        if (this.tasksListener._sortType === "incompleteDue") {
            if (DateExt.isToday(date)) {
                headerText = "Today";
            } else if (date.getTime() === DateExt.distantPast().getTime()) {
                headerText = "Overdue";
                overdue = true;
            } else if (date.getTime() === DateExt.distantFuture().getTime()) {
                headerText = "Later";
            } else {
                headerText = "Next 7 Days";
            }
        } else if (this.tasksListener._sortType === "completedList" || this.tasksListener._sortType === "incompleteList") {
            headerText = "";
        } else {
            headerText = DateExt.dateMediumString(date, navigator.language);
        }

        return (
            <div key={date} className={styles.container}>
                <div className={styles.sectionHeader}>{headerText}</div>
                <div className={styles.section}>
                    {this.renderRow(group.value, overdue)}
                </div>
            </div>
        );
    }

    renderRow(tasks, overdue) {
        const { firebase, history } = this.props;
        
        return tasks.map(task => {
            return <TaskRow key={task.identifier} task={task} overdue={overdue} firebase={firebase} history={history} />
        })
    }

    render() {
        const { tasks, showComplete, subsection, connectionName } = this.state;
        const completeToggleState = showComplete ? styles.completeToggleOn : styles.completeToggleOff
        const title = connectionName != null && connectionName !== "" ? `${connectionName} • ` : '';

        return (
            <div className={`baseContainer baseContainerMargin`}>
                {this.identifier != null && this.identifier !== '' && (
                    <div className={`pageTitle`}>{`${title}Tasks`}</div>
                )}
                <div className={`${styles.completeToggle} ${completeToggleState} tooltip`} onClick={this.toggleCompleteState}>
                    <span className={`tooltiptext`}>{"Toggle Completed"}</span>
                </div>
                <SelectorBar className={styles.selectorBar} itemTitles={["Scheduled", "List"]} selectedIndex={subsection === "Due" ? 0 : 1} selectorBarChanged={this.selectorBarChanged} />
                {
                    tasks.map(group => {
                        return this.renderSection(group)
                    })
                }
                {this.tasksListener.loadedInitially === true && tasks.length === 0 && (
                    <EmptyDataView icon={"empty_tasks.svg"} title={"You don't have any tasks yet.\n\nSelect the add button to create one."} showAddButton={true} addButtonClicked={this.createTask} />
                )}
                <AddButton className={`addButton`} onClick={this.createTask} />
            </div>
        )
    }
}

export default Tasks
