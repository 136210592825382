import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './TaskRow.module.css';
import DateExt from '../../../Extensions/DateExt';
import Avatar from '../../Elements/Avatar';
import ConnectionListener from '../../../Managers/Connections/ConnectionListener'
import CheckmarkButton from '../../Elements/CheckmarkButton';
import Task from '../../../Models/Task';

class TaskRow extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        const connectionIdentifier = props.task.connectionIdentifier;
        if (connectionIdentifier != null && connectionIdentifier !== "") {

            this.connectionListener = new ConnectionListener(this.database, this.storage, connectionIdentifier, (connection) => {

                this.setState({connectionName: connection.name});

                connection.loadUserImage(this.storage, this.functions, this.auth.currentUser, url => {
                    this.setState({connectionImageUrl: url});
                });
            });
        }

        this.state = {
            connectionName: "\xa0", // Default to unicode whitespace to remove hight jump on load
            connectionImageUrl: null
        }
    }

    componentDidMount() {

        if (this.connectionListener != null) {
            this.connectionListener.attemptStartAuth(this.auth.currentUser);
        }
    }

    componentWillUnmount() {

        if (this.connectionListener != null) {
            this.connectionListener.stop();
        }
    }

    taskChecked = (identifier, complete) => {
        const currentUser = this.auth.currentUser;
        var uid;
        if (currentUser != null) {
            uid = currentUser.uid;
        } else {
            return;
        }

        // Do after 300ms to update after animation
        setTimeout(() => {
            Task.markComplete(this.database, identifier, uid, complete)
                .catch(err => {
                    console.error(err);
                });
        }, 300);
    }

    render() {
        const { task, overdue } = this.props;
        const { connectionName, connectionImageUrl } = this.state;

        return (
            <div className={`card cardHover ${styles.row}`}>
                <CheckmarkButton identifier={task.identifier} complete={task.completed} overdue={overdue} check={this.taskChecked} />
                <Link className={styles.content} to={`/tasks/${task.identifier}`}>
                    <div className={`${styles.title} ${task.completed ? styles.titleCompleted : ''}`}>{task.name}</div>
                    <div className={styles.subtitle}>
                        <div className={styles.due}>{DateExt.dateMediumString(task.due, navigator.language)}</div>
                        <div className={styles.connectionName}>{connectionName}</div>
                    </div>
                </Link>
                <div className={styles.connectionImage}>
                    {connectionImageUrl != null && (
                        <Avatar imageUrl={connectionImageUrl} style={{ width: 24, height: 24, marginTop: 5, borderWidth: 0 }} />
                    )}
                </div>
            </div>
        )
    }
}

export default TaskRow
