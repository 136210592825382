import React, { Component } from 'react';
import styles from './ConnectionNotes.module.css';
import NotesListener from '../../../Managers/NotesListener'
import NotesCell from '../../Views/Notes/NotesCell';
import DateExt from '../../../Extensions/DateExt';
import AddButton from '../../Elements/AddButton';
import EmptyDataView from '../../Elements/EmptyDataView';
import Connection from '../../../Models/Connection';

class ConnectionNotes extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.identifier = props.match.params.id;

        this.notesListener = new NotesListener(this.database, this.storage, this.identifier, (notes) => {
            this.setState({
                notes: notes
            });
        });

        this.state = {
            notes: []
        };
    }

    componentDidMount() {
        document.title = "Vanir • Connection Notes";

        this.notesListener.attemptStartAuth(this.auth.currentUser);

        Connection.get(this.database, this.auth.currentUser.uid, this.identifier)
            .then(connection => {

                if (connection == null) {
                    return;
                }

                this.setState({
                    connectionName: connection.name
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    componentWillUnmount() {
        this.notesListener.stop();
    }

    createNote = () => {
        const { history } = this.props;
        const { subsection } = this.state;

        history.push(`/connections/${this.identifier}/notes/new`, {
            subsection: subsection,
            connectionId: this.identifier
        });
    }

    renderSection(group) {

        const date = group.key;
        const headerText = DateExt.dateMediumString(date, navigator.language);

        return (
            <div key={date} className={styles.container}>
                <div className={styles.sectionHeader}>{headerText}</div>
                <div className={styles.section}>
                    {this.renderRows(group.value)}
                </div>
            </div>
        );
    }

    renderRows(notes) {
        const { history } = this.props;
        
        return notes.map((note, index) => {
            return <NotesCell key={note.identifier} note={note} connectionId={this.identifier} history={history} lastItem={index === notes.length - 1} />
        })
    }

    render() {
        const { notes, connectionName } = this.state;
        const title = connectionName != null && connectionName !== "" ? `${connectionName} • ` : '';

        return (
            <div className={`baseContainer`}>
                <div className={`pageTitle`}>{`${title}Notes`}</div>
                <div className={`card`}>
                    {
                        notes.map(group => {
                            return this.renderSection(group)
                        })
                    }
                </div>
                {this.notesListener.loadedInitially === true && notes.length === 0 && (
                    <EmptyDataView icon={"empty_notes.svg"} title={"You don't have any notes yet.\n\nSelect the add button to create one."} showAddButton={true} addButtonClicked={this.createNote} />
                )}
                <AddButton className={`addButton`} onClick={this.createNote} />
            </div>
        )
    }
}

export default ConnectionNotes;
