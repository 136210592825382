import Connection from '../../Models/Connection';
import Member from '../../Models/Member';
import DateExt from '../../Extensions/DateExt';

class ConnectionsListener {

    listener = null;
    sortType = Connection.SortType.lastName;
    uid = null;
    connectionId = null;
    loadedInitially = false;

    constructor(database, storage, connectionId, dataUpdated) {
        
        const connectionSortType = parseInt(localStorage.connectionSortType);
        if (connectionSortType === Connection.SortType.firstName) {
            this.sortType = Connection.SortType.firstName;
        } else if (connectionSortType === Connection.SortType.lastName) {
            this.sortType = Connection.SortType.lastName;
        } else if (connectionSortType === Connection.SortType.rating) {
            this.sortType = Connection.SortType.rating;
        } else if (connectionSortType === Connection.SortType.connectedOrCreated) {
            this.sortType = Connection.SortType.connectedOrCreated;
        } else if (connectionSortType === Connection.SortType.updated) {
            this.sortType = Connection.SortType.updated;
        }

        this.database = database;
        this.storage = storage;
        this.connectionId = connectionId;
        this.dataUpdated = dataUpdated;
    }

    setSortType(sortType) {
        const uid = this.uid;
        this.stop();
        this.dataUpdated([]);
        localStorage.connectionSortType = sortType;
        this.sortType = sortType;
        this.attemptStart(uid);
    }
    
    attemptStartAuth(currentUser) {

        if (currentUser == null) {
            return;
        }

        const uid = currentUser.uid;
        this.attemptStart(uid);
    }

    attemptStart(uid) {

        if (this.listener != null) {
            return;
        }

        this.uid = uid;
        var query = this.database.collection(`users/${uid}/connections`);
        if (this.sortType === Connection.SortType.firstName) {
            query = query.orderBy("firstName").orderBy("lastName").orderBy("rating", "desc");
        } else if (this.sortType === Connection.SortType.lastName) {
            query = query.orderBy("lastName").orderBy("firstName").orderBy("rating", "desc");
        } else if (this.sortType === Connection.SortType.rating) {
            query = query.orderBy("rating", "desc").orderBy("lastName").orderBy("firstName");
        } else if (this.sortType === Connection.SortType.connectedOrCreated) {
            query = query.orderBy("connected", "desc").orderBy("created", "desc").orderBy("lastName").orderBy("firstName");
        } else if (this.sortType === Connection.SortType.updated) {
            query = query.orderBy("updated", "desc").orderBy("lastName").orderBy("firstName");
        } else {
            return;
        }

        this.listener = query.onSnapshot((snapshot) => {
            var connections = [];
            snapshot.forEach(doc => {
                if (doc.exists === true) {

                    if (doc.get("type") === "member") {
                        const member = new Member(doc.data(), doc.id);
                        connections.push(member);
                    } else {
                        const connection = new Connection(doc.data(), doc.id);
                        connections.push(connection);
                    }
                }
            });

            const sortedConnections = this.sortConnections(connections);

            this.loadedInitially = true;

            if (this.dataUpdated) {
                this.dataUpdated(sortedConnections);
            }
        });
    }

    sortConnections(connections) {
        const sortType = this.sortType;

        const groupedConnections = connections.reduce((acc, value) => {

            var key = null;
            if (sortType === Connection.SortType.firstName) {
                key = value.firstName.toUpperCase()[0];
            } else if (sortType === Connection.SortType.lastName) {
                key = value.lastName.toUpperCase()[0];
            } else if (sortType === Connection.SortType.rating) {
                key = value.rating;
            } else if (sortType === Connection.SortType.connectedOrCreated) {
                if (value.connected != null) {
                    key = DateExt.stripTime(value.connected);
                } else {
                    key = DateExt.stripTime(value.created);
                }
            } else if (sortType === Connection.SortType.updated) {
                key = DateExt.stripTime(value.updated);
            }

            if (key != null) {
                (acc[key] = acc[key] || []).push(value);
            }

            return acc;
        }, {});

        const keys = Object.keys(groupedConnections).map(key => {
            if (sortType === Connection.SortType.rating) {
                return parseInt(key);
            } else if (sortType === Connection.SortType.connectedOrCreated || sortType === Connection.SortType.updated) {
                return new Date(key);
            }

            return key;
        })

        var sortedKeys = keys.sort((firstEl, secondEl) => {

            if (firstEl.key === Connection.anonymousTag) {
                return -1;
            } else if (sortType === Connection.SortType.firstName || sortType === Connection.SortType.lastName) {

                if (firstEl < secondEl) {
                    return -1;
                } else if (firstEl > secondEl) {
                    return 1;
                }
                return 0;

            } else if (sortType === Connection.SortType.rating) {

                if (firstEl < secondEl) {
                    return 1;
                } else if (firstEl > secondEl) {
                    return -1;
                }
                return 0;

            } else if (sortType === Connection.SortType.connectedOrCreated || sortType === Connection.SortType.updated) {
                
                const firstTime = firstEl.getTime();
                const secondTime = secondEl.getTime();

                if (firstTime < secondTime) {
                    return 1;
                } else if (firstTime > secondTime) {
                    return -1;
                }
                return 0;
            }

            return 0;
        });

        var sortedConnections = [];
        sortedKeys.forEach(key => {
            sortedConnections.push(
                {key: key, value: groupedConnections[key]}
            );
        });

        return sortedConnections;
    }

    filterConnections(searchText, connections) {

        if (connections == null || connections.length === 0) {
            return;
        }

        if (searchText === "") {
            return null;
        }

        const terms = searchText.split(" ");
        return connections.map(group => {

            const filteredGroup = group.value.filter(item => {

                // TODO: Filter out adverts when implemented

                for (const term of terms) {

                    if (term[0] === "#") {

                        // This term is a tag
                        const tag = term.substring(1); // Remove the #
                        const exists = item.tags.filter(item => {
                            return item.includes(tag);
                        });

                        if (exists.length > 0) {
                            return item;
                        } else {
                            continue;
                        }
                        
                    } else {

                        // This term is a normal string search
                        if (item.firstName.toLowerCase().includes(term) || item.lastName.toLowerCase().includes(term)) {
                            return item;
                        }
                    }
                }

                return null;
            });

            if (filteredGroup.length > 0) {
                return {key: group.key, value: filteredGroup};
            }

            return null;

        }).filter(item => item != null);
    }

    stop() {
        if (this.listener == null) {
            return;
        }

        this.listener();
        this.listener = null;
        this.uid = null;
        this.connectionId = null;
    }

}

export default ConnectionsListener
