import React, { Component } from 'react';
import styles from './Share.module.css';
import sharedStyles from '../Shared/ProfileShared.module.css';
import MemberListener from '../../../Managers/Connections/MemberListener';
import CardsContainer from '../../Views/Cards/CardsContainer';
import Button from '../../Elements/Button';
import Avatar from '../../Elements/Avatar';
import AppStoreIcon from '../../Elements/AppStoreIcon';
const queryString = require('query-string');

class Share extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.memberListener = new MemberListener(this.database, this.storage, (member) => {

            member.loadUserImage(this.storage, this.functions, null, url => {
                this.setState({userImageUrl: url});
            });

            this.setState({
                name: member.name,
                aboutMe: member.aboutMe
            });

            if (member.name !== "") {
                document.title = `Vanir • ${member.name}`
            }
        });

        const parsedLocation = queryString.parse(props.location.search);
        this.userId = parsedLocation.user;
        this.cards = parsedLocation.cards;

        this.state = {
            name: "Loading...",
            aboutMe: null,
            userImageUrl: null,
            exportingContact: false
        };
    }

    componentDidMount() {
        document.title = "Vanir"

        this.memberListener.attemptStart(this.userId);
    }

    componentWillUnmount() {
        this.memberListener.stop();
    }

    exportContact = () => {
        const { name, exportingContact } = this.state;

        if (this.userId == null || this.userId === "" || name == null || name === "") {
            return
        }

        if (exportingContact === true) {
            return
        }

        this.setState({
            exportingContact: true
        });

        const createvCard = this.functions.httpsCallable("createvCard");
        createvCard({memberId: this.userId})
            .then((result) => {

                // Revoke any previously created urls
                if (this.vCardUrl != null) {
                    window.URL.revokeObjectURL(this.vCardUrl);
                }

                // Get the vCard string
                const vCard = result.data.vCard;

                // Create a Blob from the vCard
                const contentType = "text/vcard";
                var blob = new Blob([vCard], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                
                // Make the url the current url
                window.location.href = url;

                this.setState({
                    exportingContact: false
                });

                this.vCardUrl = url
            })
            .catch(err => {
                console.error(err);
            });
    }

    openInApp = () => {

        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS === false) {
            return
        }

        var url = document.URL;
        if (url.startsWith("https://")) {
            url = url.replace("https://", "vanirapp://");
        } else if (url.startsWith("http://")) {
            url = url.replace("http://", "vanirapp://");
        }
        window.open(url);
    }

    render() {
        const { aboutMe, exportingContact, name, userImageUrl } = this.state;

        var aboutMeComp = null;
        if (aboutMe != null) {
            aboutMeComp = (
                <div className={sharedStyles.aboutMe}>
                    <div className={sharedStyles.aboutMeTitle}>About Me</div>
                    <div className={sharedStyles.aboutMeText}>{aboutMe}</div>
                </div>
            );
        }

        var buttons = [];
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (iOS === true) {
            buttons = [
                <Button key="button1" title="Export Contact" onClick={this.exportContact} loading={exportingContact} />,
                <Button key="button2" title="Open in App" onClick={this.openInApp} />
            ];
        } else {
            buttons = [
                <Button key="button1" title="Export Contact" onClick={this.exportContact} loading={exportingContact} />
            ];
        }

        return (
            <div className={sharedStyles.profile}>
                <div className={`pageTitle`}>{name}</div>
                <div className={`${sharedStyles.card} ${sharedStyles.cardFull}`}>
                    <Avatar imageUrl={userImageUrl} style={{ width: 116, height: 116, marginTop: -69 }} />
                    {aboutMeComp}
                </div>
                <CardsContainer firebase={this.props.firebase} userId={this.userId} cards={this.cards} />
                <div className={sharedStyles.buttons}>{buttons}</div>
                <div className={styles.appStoreContainer}>
                    <AppStoreIcon />
                </div>
            </div>
        );
    }
}

export default Share;
