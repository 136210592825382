import React, { Component } from 'react';

class Terms extends Component {
    componentDidMount(){
        document.title = "Vanir • Terms & Conditions"
    }

    render() {
        return (
            <div className="card cardFull">
                <h1 id="termsandconditions">Terms and Conditions</h1>
        
                <h2 id="lastupdated">Last updated: May 24, 2018</h2>
        
                <p>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with Vanir mobile
                    application (the "Service") operated by Frostlight Solutions AB ("us", "we", or "our").</p>
        
                <p>Please read these Terms and Conditions carefully before using our Vanir mobile application (the "Service").</p>
        
                <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
                    These Terms apply to all visitors, users and others who access or use the Service.</p>
        
                <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the
                    terms then you may not access the Service.</p>
        
                <h3 id="1purchases">1. Purchases</h3>
        
                <ul>
                    <li>If you wish to purchase any product or service made available through the Service ("Purchase"), you may
                        be asked to supply certain information relevant to your Purchase including, without limitation, your
                        credit card number, the expiration date of your credit card, your billing address, and your shipping
                        information.</li>
        
                    <li>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
                        method(s) in connection with any Purchase; and that (ii) the information you supply to us is true,
                        correct and complete.</li>
        
                    <li>By submitting such information, you grant us the right to provide the information to third parties for
                        purposes of facilitating the completion of Purchases.</li>
        
                    <li>We reserve the right to refuse or cancel your order at any time for certain reasons including but not
                        limited to: product or service availability, errors in the description or price of the product or
                        service, error in your order or other reasons.</li>
        
                    <li>We reserve the right to refuse or cancel your order if fraud or an unauthorised or illegal transaction
                        is suspected.</li>
                </ul>
        
                <h3 id="2availabilityerrorsandinaccuracies">2. Availability, Errors and Inaccuracies</h3>
        
                <ul>
                    <li>We are constantly updating our offerings of services on the Service. The services available on our
                        Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in
                        updating information on the Service and in our advertising on other web sites.</li>
        
                    <li>We cannot and do not guarantee the accuracy or completeness of any information, including prices,
                        specifications, availability, and services. We reserve the right to change or update information and to
                        correct errors, inaccuracies, or omissions at any time without prior notice.</li>
                </ul>
        
                <h3 id="3subscriptions">3. Subscriptions</h3>
        
                <ul>
                    <li>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in
                        advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly
                        or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</li>
        
                    <li>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same
                        conditions unless you cancel it or Frostlight Solutions AB cancels it. You may cancel your Subscription
                        renewal either through your online account management page or by contacting Frostlight Solutions AB
                        customer support team.</li>
        
                    <li>A valid payment method, including credit card, is required to process the payment for your
                        Subscription. You shall provide Frostlight Solutions AB with accurate and complete billing information
                        including full name, address, telephone number, and a valid payment method information. By submitting
                        such payment information, you automatically authorize Frostlight Solutions AB to charge all
                        Subscription fees incurred through your account to any such payment instruments.</li>
        
                    <li>Should automatic billing fail to occur for any reason, Frostlight Solutions AB will issue an electronic
                        invoice indicating that you must proceed manually, within a certain deadline date, with the full
                        payment corresponding to the billing period as indicated on the invoice.</li>
                </ul>
        
                <h3 id="4feechanges">4. Fee Changes</h3>
        
                <ul>
                    <li>Frostlight Solutions AB, in its sole discretion and at any time, may modify the Subscription fees for
                        the Subscriptions. Any Subscription fee change will become effective at the end of the then-current
                        Billing Cycle.</li>
        
                    <li>Frostlight Solutions AB will provide you with a reasonable prior notice of any change in Subscription
                        fees to give you an opportunity to terminate your Subscription before such change becomes effective.</li>
        
                    <li>Your continued use of the Service after the Subscription fee change comes into effect constitutes your
                        agreement to pay the modified Subscription fee amount.</li>
                </ul>
        
                <h3 id="5refunds">5. Refunds</h3>
        
                <ul>
                    <li>Except when required by law, paid Subscription fees are non-refundable.</li>
                </ul>
        
                <h3 id="6content">6. Content</h3>
        
                <ul>
                    <li>Our Service allows you to post, link, store, share and otherwise make available certain information,
                        text, graphics, videos, or other material ("Content"). You are responsible for the Content that you
                        post to the Service, including its legality, reliability, and appropriateness.</li>
        
                    <li>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform,
                        publicly display, reproduce, and distribute such Content on and through the Service. You retain any and
                        all of your rights to any Content you submit, post or display on or through the Service and you are
                        responsible for protecting those rights. You agree that this license includes the right for us to make
                        your Content available to other users of the Service, who may also use your Content subject to these
                        Terms.</li>
        
                    <li>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it
                        and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on
                        or through the Service does not violate the privacy rights, publicity rights, copyrights, contract
                        rights or any other rights of any person.</li>
                </ul>
        
                <h3 id="7accounts">7. Accounts</h3>
        
                <ul>
                    <li>When you create an account with us, you must provide us information that is accurate, complete, and
                        current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                        termination of your account on our Service.</li>
        
                    <li>You are responsible for safeguarding the password that you use to access the Service and for any
                        activities or actions under your password, whether your password is with our Service or a third-party
                        service.
                        You agree not to disclose your password to any third party. You must notify us immediately upon
                        becoming aware of any breach of security or unauthorized use of your account.</li>
                </ul>
        
                <h3 id="8intellectualproperty">8. Intellectual Property</h3>
        
                <ul>
                    <li>The Service and its original content (excluding Content provided by users), features and functionality
                        are and will remain the exclusive property of Frostlight Solutions AB and its licensors. The Service is
                        protected by copyright, trademark, and other laws of both the Sweden and foreign countries. Our
                        trademarks and trade dress may not be used in connection with any product or service without the prior
                        written consent of Frostlight Solutions AB.</li>
                </ul>
        
                <h3 id="9linkstootherwebsites">9. Links To Other Websites</h3>
        
                <ul>
                    <li>Our Service may contain links to third-party web sites or services that are not owned or controlled by
                        Frostlight Solutions AB.</li>
        
                    <li>Frostlight Solutions AB has no control over, and assumes no responsibility for, the content, privacy
                        policies, or practices of any third party web sites or services. You further acknowledge and agree that
                        Frostlight Solutions AB shall not be responsible or liable, directly or indirectly, for any damage or
                        loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
                        goods or services available on or through any such web sites or services.</li>
        
                    <li>We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                        sites or services that you visit.</li>
                </ul>
        
                <h3 id="10termination">10. Termination</h3>
        
                <ul>
                    <li>We may terminate or suspend your account immediately, without prior notice or liability, for any reason
                        whatsoever, including without limitation if you breach the Terms.</li>
        
                    <li>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
                        account, you may simply discontinue using the Service.</li>
                </ul>
        
                <h3 id="11limitationofliability">11. Limitation Of Liability</h3>
        
                <ul>
                    <li>In no event shall Frostlight Solutions AB, nor its directors, employees, partners, agents, suppliers,
                        or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                        including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                        resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct
                        or content of any third party on the Service; (iii) any content obtained from the Service; and (iv)
                        unauthorized access, use or alteration of your transmissions or content, whether based on warranty,
                        contract, tort (including negligence) or any other legal theory, whether or not we have been informed
                        of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its
                        essential purpose.</li>
                </ul>
        
                <h3 id="12disclaimer">12. Disclaimer</h3>
        
                <ul>
                    <li>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                        basis. The Service is provided without warranties of any kind, whether express or implied, including,
                        but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                        non-infringement or course of performance.</li>
        
                    <li>Frostlight Solutions AB its subsidiaries, affiliates, and its licensors do not warrant that a) the
                        Service will function uninterrupted, secure or available at any particular time or location; b) any
                        errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or
                        d) the results of using the Service will meet your requirements.</li>
                </ul>
        
                <h3 id="13governinglaw">13. Governing Law</h3>
        
                <ul>
                    <li>These Terms shall be governed and construed in accordance with the laws of Sweden, without regard to
                        its conflict of law provisions.</li>
        
                    <li>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                        rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
                        remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement
                        between us regarding our Service, and supersede and replace any prior agreements we might have between
                        us regarding the Service.</li>
                </ul>
        
                <h3 id="14changes">14. Changes</h3>
        
                <ul>
                    <li>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                        revision is material we will try to provide at least 30 days notice prior to any new terms taking
                        effect. What constitutes a material change will be determined at our sole discretion.</li>
        
                    <li>By continuing to access or use our Service after those revisions become effective, you agree to be
                        bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</li>
                </ul>
        
                <h3 id="15contactus">15. Contact Us</h3>
        
                <ul>
                    <li>If you have any questions about these Terms, please contact us at <a href="mailto:info@frostlight.se">info@frostlight.se</a>.</li>
                </ul>
            </div>
        );
    }
}

export default Terms;
