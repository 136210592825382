import React, { Component } from 'react';
import styles from './EmptyDataView.module.css';
import AddButton from './AddButton'

class EmptyDataView extends Component {
    render() {
        const { title, icon, type, showAddButton, addButtonClicked } = this.props;

        var styleType = styles.default;
        if (type === 'light') {
            styleType = styles.light;
        }

        return (
            <div className={styles.base}>
                <div className={`${styles.card} ${styleType} ${showAddButton === true ? styles.cardAdd : styles.cardDefault}`}>
                    <img className={styles.icon} alt="Empty Data View Icon" src={`/img/${icon}`} />
                    <div className={styles.title}>{title}</div>
                    {showAddButton === true && (
                        <div className={styles.addButtonContainer}>
                            <AddButton className={styles.addButton} onClick={addButtonClicked} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default EmptyDataView
