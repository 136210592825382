import React, { Component } from 'react';
import styles from './AddButton.module.css';

class AddButton extends Component {
    render() {
        const { className, style, onClick } = this.props;

        return (
            <button className={`${styles.addButton} ${className || ""}`} onClick={onClick} style={style}>
                <img className={styles.image} alt="Avatar" src="/img/add-button.svg" />
            </button>
        );
    }
}

export default AddButton;
