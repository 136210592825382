import React, { Component } from 'react';
import styles from './Avatar.module.css';

class Avatar extends Component {
    render() {
        const { imageUrl, style } = this.props;

        if (imageUrl == null) {
            return <img className={styles.image} alt="Avatar" src="/img/avatar-placeholder.svg" style={style} />
        } else {
            return <img className={styles.image} alt="Avatar" src={imageUrl} style={style} />
        }
    }
}

export default Avatar;
