export default async function ImageDownloader(functions, filename, path, name, ref) {

    const imageRefPath = `${path}/${name}.imageRef`;
    return new Promise((resolve, reject) => {
        // Try fetch from session storage
        const imageRefJSON = sessionStorage.getItem(imageRefPath);
        if (imageRefJSON == null || imageRefJSON === "") {
            resolve(null);
            return;
        }

        var imageRef = null;
        try {
            imageRef = JSON.parse(imageRefJSON);
        } catch(err) {
            resolve(null);
            return;
        }
        
        if (imageRef == null) {
            resolve(null);
            return;
        }

        if (filename !== imageRef.filename) {
            resolve(null);
        } else {
            resolve(imageRef.url);
        }

    })
    .then(localUrl => {

        if (localUrl != null) {
            return localUrl;
        }

        // Create a ref for the thumbnail image
        const thumbRef = ref.parent.child(`thumb_${ref.name}`);

        // If nothing or different url, then fetch from firebase
        return thumbRef.getDownloadURL()
            .then(url => {

                const imageRefJSON = JSON.stringify({
                    filename: filename,
                    url: url
                });

                // Save to session storage
                sessionStorage.setItem(imageRefPath, imageRefJSON);

                return url;
            })
            .catch(err => {
                if (err.code === "storage/object-not-found") {
                    console.log(`Error fetching: ${thumbRef.fullPath}`);

                    // Request creating a thumnail image
                    const thumbnailRefresh = functions.httpsCallable('thumbnailRefresh');
                    return thumbnailRefresh({fullPath: ref.fullPath, thumbFullPath: thumbRef.fullPath}).then(result => {

                        // Get URL and save to local storage
                        const url = result.data.url;
                        const imageRefJSON = JSON.stringify({
                            filename: filename,
                            url: url
                        });
        
                        // Save to session storage
                        sessionStorage.setItem(imageRefPath, imageRefJSON);

                        return url;
                    });
                }
            });
    });
}
