import React, { Component } from 'react';
import styles from './CardsContainer.module.css';
import CardView from './CardView'
import CardsListener from '../../../Managers/CardsListener';

class CardsContainer extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();

        const { connection, userId, cards } = this.props;
        const currentUser = this.auth.currentUser;
        var type = null;
        if (currentUser != null && connection != null) {
            type = CardsListener.Type.connection;
        } else if (userId != null && cards === "*") {
            type = CardsListener.Type.profile;
        }

        this.cardsListener = new CardsListener(this.database, this.storage, type, props.userId || props.connection.identifier, (cards) => {
            this.setState({
                cards: cards
            });
        });

        this.state = {
            cards: []
        }
    }

    componentDidMount() {
        this.cardsListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.cardsListener.stop();
    }

    render() {
        const { showFavorites, connection, userId, editable } = this.props;
        const { cards } = this.state;

        return (
            <div className={styles.cardsContainer}>
                {
                    cards.map(card => {
                        return <CardView key={card.identifier} card={card} connection={connection} userId={userId} firebase={this.props.firebase} showFavorites={showFavorites} editable={editable} />;
                    })
                }
            </div>
        );
    }
}

export default CardsContainer;
