import React, { Component } from 'react';
// import styles from './Profile.module.css';
import sharedStyles from '../Shared/ProfileShared.module.css';
import Avatar from '../../Elements/Avatar';
import CardsContainer from '../../Views/Cards/CardsContainer';
import MemberListener from '../../../Managers/Connections/MemberListener';
import ShareButton from '../../Elements/ShareButton';
import InputDetail from '../../Elements/InputDetail';
import TextAreaDetail from '../../Elements/TextAreaDetail';
import Button from '../../Elements/Button';
import AddButton from '../../Elements/AddButton';
import Card from '../../../Models/Card';
import CardsListener from '../../../Managers/CardsListener';

class Profile extends Component {

    phoneNumbers = [];
    emails = [];

    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.identifier = props.match.params.id;

        this.memberListener = new MemberListener(this.database, this.storage, (member) => {

            member.loadUserImage(this.storage, this.functions, null, url => {
                this.setState({userImageUrl: url});
            });

            this.firstName = member.firstName || "";
            this.lastName = member.lastName || "";
            this.aboutMe = member.aboutMe || "";
            this.userImageFile = null;

            this.setState({
                member: member
            });
        });

        this.state = {
            member: null,
            userImageUrl: null,
            editing: false,
            isSaving: false,
            memberEdited: 0
        };
    }

    componentDidMount() {
        document.title = "Vanir • Profile";

        this.memberListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.memberListener.stop();
    }

    toggleEdit = () => {
        const { editing } = this.state;

        if (editing === false) { // So will be true after state update
            this.memberListener.stop();
        }

        this.setState({editing: !editing})
    }

    firstNameChanged = (event) => {
        const { memberEdited } = this.state;

        this.firstName = event.target.value;

        this.setState({memberEdited: memberEdited+1});
    }

    lastNameChanged = (event) => {
        const { memberEdited } = this.state;

        this.lastName = event.target.value;

        this.setState({memberEdited: memberEdited+1});
    }

    aboutMeChanged = (event) => {
        const { memberEdited } = this.state;

        this.aboutMe = event.target.value;

        this.setState({memberEdited: memberEdited+1});
    }

    imageInputDidChange = () => {
        const input = document.querySelector('#userImage');

        if (input == null || input.files.length === 0) {
            return;
        }

        const file = input.files[0];
        this.userImageFile = file;
        const url = window.URL.createObjectURL(file);
        this.setState({userImageUrl: url});
    }

    deleteUserImage = () => {

        if (window.confirm("Are you sure you want to delete your user image?")) { 

            const { member } = this.state;

            member.deleteUserImage(this.database, this.storage, this.auth.currentUser)
                .then(() => {
                    const input = document.querySelector('input');
                    input.value = null;

                    this.userImageFile = null;
                    this.setState({userImageUrl: null});
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    cancelEdit = () => {
        this.setState({editing: false});

        this.memberListener.attemptStartAuth(this.auth.currentUser);
    }

    saveProfile = () => {
        const { member } = this.state;

        member.firstName = this.firstName;
        member.lastName = this.lastName;
        member.aboutMe = this.aboutMe;

        this.setState({isSaving: true});

        member.save(this.database, this.storage, this.auth.currentUser, this.userImageFile)
            .then(() => {

                this.userImageFile = null;
                this.setState({editing: false, isSaving: false})
                this.memberListener.attemptStartAuth(this.auth.currentUser);
            })
            .catch(err => {
                console.error(err);
            });
    }

    shareButtonClicked = () => {
        const { history } = this.props;

        history.push(`/profile/share`)
    }

    createCard = () => {
        const { member } = this.state;
        const currentUser = this.auth.currentUser;

        if (currentUser == null || member == null) {
            return;
        }

        CardsListener.numberOfCards(this.database, CardsListener.Type.profile, null, currentUser.uid)
            .then(cardsCount => {

                if (cardsCount >= Card.limit) {
                
                    alert('Card Limit Reached\n\nYou have reached the current card limit. This limit may change in later versions.');
                    return
                }

                const card = new Card({
                    type: 'business',
                    connectionIdentifier: member.identifier
                });
                card.create(this.database, null, currentUser)
                    .catch(err => {
                        console.error(err);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    }

    renderDetail() {
        const { member, editing, isSaving } = this.state;

        if (editing === true) {

            return (
                <div>
                    <InputDetail title="First Name" icon="/img/grey-first-name.svg" type="text" name="firstname" placeholder="First Name" value={this.firstName} autofocus="true" onChange={this.firstNameChanged} />
                    <InputDetail title="Last Name" icon="/img/grey-last-name.svg" type="text" name="lastname" placeholder="Last Name" value={this.lastName} autofocus="false" onChange={this.lastNameChanged} />
                    <TextAreaDetail title="About Me" icon="/img/grey-notes.svg" type="text" name="text" placeholder="About Me" value={this.aboutMe} autofocus="false" onChange={this.aboutMeChanged} />
                    <div className={sharedStyles.buttons}>
                        <Button className={sharedStyles.button} buttonStyle="whiteDefault" title="Cancel" disabled={isSaving} onClick={this.cancelEdit} />
                        <Button className={sharedStyles.button} title="Save" onClick={this.saveProfile} loading={isSaving} />
                    </div>
                </div>
            )

        } else {

            const aboutMe = member.aboutMe || "";

            return (
                <div className={sharedStyles.aboutMe}>
                    <div className={sharedStyles.aboutMeTitle}>About Me</div>
                    <div className={sharedStyles.aboutMeText}>{aboutMe}</div>
                </div>
            );
        }
    }

    render() {
        const { member, userImageUrl, editing } = this.state;
        const currentUser = this.auth.currentUser;

        if (member == null || currentUser == null) {
            return <div className={`pageTitle`}>Loading...</div>
        }

        var avitarActionWord = "Click";
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            avitarActionWord = "Tap";
        }

        return (
            <div>
                <div className={sharedStyles.profile}>
                    <div className={`pageTitle ${sharedStyles.titleContainer}`}>
                        <div className={`${sharedStyles.title} ${editing === false && sharedStyles.titleEdit}`}>{editing ? "Editing" : member.name}</div>
                        {editing === false && (
                            <button className={sharedStyles.edit} onClick={this.toggleEdit} />
                        )}
                    </div>
                    <div className={`${sharedStyles.card} ${sharedStyles.cardFull}`}>
                        {member.type === 'member' && (
                            <div className={sharedStyles.share}>
                                <ShareButton memberType={member.type} onClick={this.shareButtonClicked} />
                            </div>
                        )}
                        <Avatar imageUrl={userImageUrl} style={{ width: 116, height: 116, marginTop: -69 }} />
                        {editing === true && (
                            <div className={sharedStyles.avitarEditing} onClick={this.selectUserImage}>
                                <label>{avitarActionWord} to<br/>Add Image</label>
                                <input className={sharedStyles.avitarEditing} type="file" id="userImage" name="userImage" accept="image/jpg, image/jpeg" onChange={this.imageInputDidChange} />
                                {member.userImageFilename !== "" && (
                                    <div className={sharedStyles.avitarClear} onClick={this.deleteUserImage} />
                                )}
                            </div>
                        )}
                        {this.renderDetail()}
                    </div>
                    <CardsContainer firebase={this.props.firebase} userId={currentUser.uid} cards={"*"} editable={true} />
                </div>
                <AddButton className={`addButton`} onClick={this.createCard} />
            </div>
        );
    }
}

export default Profile
