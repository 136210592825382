import React, { Component } from 'react';
import styles from './SettingsDropdown.module.css';
import Button from '../../Elements/Button';

class SettingsDropdown extends Component {
    constructor(props) {
        super(props);

        this.auth = props.auth;

        this.state = {
            isLoggingOut: false
        };
    }

    logout = () => {
        this.setState({
            isLoggingOut: true
        });

        this.auth.signOut()
            .then(() => {
    
                this.setState({
                    isLoggingOut: false
                });

                // Push back to home
                this.props.history.push('/');

            })
            .catch((error) => {
                console.error(error);

                this.setState({
                    isLoggingOut: false
                });
            });
    }

    render() {
        const { reference, shouldShowDropdown, name } = this.props;
        const { isLoggingOut } = this.state;
        const currentUser = this.auth.currentUser;

        return (
            <div ref={reference}>{ shouldShowDropdown === true ? (
                <div className={`card ${styles.dropdown}`}>
                    <div className={styles.details}>
                        <span className={styles.name}> {name} </span>
                        { currentUser != null && currentUser.email != null && (
                            <span className={styles.email}> {currentUser.email} </span>
                        )}
                    </div>
                    <div className={styles.rowSeperator}></div>
                    <Button title="Logout" onClick={this.logout} loading={isLoggingOut} />
                </div>
            ) : (null) }</div>
        );
    }
}

export default SettingsDropdown
