class Note {
    constructor(document, identifier) {

        if (document == null) {
            document = {};
        }

        this.identifier = identifier;
        this.text = document.text || "";
        this.connectionIdentifier = document.connectionIdentifier || null;

        if (document.posted != null) {
            this.posted = document.posted.toDate();
        } else {
            this.posted = new Date();
        }
    }

    firestoreDoc = () => {
        return {
            text: this.text,
            connectionIdentifier: this.connectionIdentifier,
            posted: this.posted || new Date()
        };
    }

    static create(database, uid, connectionId, note) {
        return database.collection(`users/${uid}/connections/${connectionId}/notes`)
            .add(note.firestoreDoc())
            .then((docRef) => {
                return docRef.id;
            });
    }

    create = (database, uid, connectionId) => {
        return Note.create(database, uid, connectionId, this);
    }

    static save(database, uid, connectionId, note) {
        const identifier = note.identifier;
        return database.doc(`users/${uid}/connections/${connectionId}/notes/${identifier}`)
            .update(note.firestoreDoc());
    }

    save = (database, uid, connectionId) => {
        return Note.save(database, uid, connectionId, this);
    }

    static delete(database, uid, connectionId, identifier) {
        return database.doc(`users/${uid}/connections/${connectionId}/notes/${identifier}`)
            .delete();
    }

    delete = (database, uid, connectionId) => {
        return Note.delete(database, uid, connectionId, this.identifier);
    }

    static get(database, uid, connectionId, identifier) {
        return database.doc(`users/${uid}/connections/${connectionId}/notes/${identifier}`)
            .get()
            .then(snapshot => {
                if (snapshot.exists === true) {
                    return new Note(snapshot.data(), snapshot.id);
                }
            });
    }
}

export default Note
