import React, { Component } from 'react';
import styles from './Detail.module.css';

class Detail extends Component {
    renderDetail(isLink) {
        const { icon, title, value, badge, accessoryIcon, hideSeperator, style, onClick } = this.props;
        const isClickable = isLink || onClick;

        return (
            <div className={isClickable ? styles.base : ''} onClick={onClick}>
                <div className={`${styles.cardRow} ${style || styles.cardRowDefault}`}>
                    {icon != null ? (
                        <img className={styles.cardDetailIcon} alt="Icon" src={icon} />
                    ) : (
                        <div className={styles.cardDetailPadding} />
                    )}
                    <div className={styles.cardDetail}>
                        {title != null && (
                            <div className={styles.cardDetailTitle}>{title}</div>
                        )}
                        <div className={styles.cardDetailValue}>{value}</div>
                    </div>
                    {badge != null && (
                        <div className={styles.badgeContainer}>
                            <div className={styles.badge}>{badge}</div>
                        </div>
                    )}
                    {accessoryIcon != null && (
                        <img className={styles.cardDetailAccessoryIcon} alt="Accessory Icon" src={accessoryIcon} />
                    )}
                </div>
                {hideSeperator !== true && (
                    <div className={styles.rowSeperator}></div>
                )}
            </div>
        );
    }

    render() {
        const { prefix, value } = this.props;

        var link = `${prefix}${value}`;
        if (prefix === "https://") {
            if (value.includes("http://") || value.includes("https://")) {
                link = value
            }
        }

        if (prefix != null) {
            return(
                <a className={styles.cardDetailLink} href={link} target="_blank" rel="noopener noreferrer">
                    {this.renderDetail(true)}
                </a>
            )
        }

        return this.renderDetail();
    }
}

export default Detail;
