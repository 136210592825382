import React, { Component } from 'react';
import styles from './SearchBar.module.css';

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.input = React.createRef();
    }

    componentDidMount() {
        const { focusOnMount } = this.props;

        if (focusOnMount === true) {
            this.input.current.focus();
        }
    }

    render() {
        const { className, name, placeholder, onChange } = this.props;

        return (
            <div className={`card cardHover ${className || styles.default} ${styles.bar}`}>
                <div className={styles.icon} />
                <input className={styles.searchText} ref={this.input} type="search" name={name || "VanirSearch"} placeholder={placeholder || ""} onChange={onChange} />
                <div className={styles.clear} />
            </div>
        )
    }
}

export default SearchBar
