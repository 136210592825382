class DateExt {

    static distantFuture() {
        return new Date("January 1 4001 0:00:00 AM GMT+00:00");
    }

    static distantPast() {
        return new Date("January 1 1 0:00:00 AM GMT+00:00");
    }

    static isToday(date) {

        let now = new Date();
        return (
            now.getFullYear() === date.getFullYear() &&
            now.getMonth() === date.getMonth() &&
            now.getDate() === date.getDate()
        );
    }

    static addDays(date, days) {
        var newDate = new Date(date.getTime());
        newDate.setDate(date.getDate() + days);
        return newDate;
    }

    static stripTime(date) {
        var newDate = new Date(date.getTime());
        newDate.setMilliseconds(0);
        newDate.setSeconds(0);
        newDate.setMinutes(0);
        newDate.setHours(0);
        return newDate;
    }

    static stripSeconds(date) {
        var newDate = new Date(date.getTime());
        newDate.setMilliseconds(0);
        newDate.setSeconds(0);
        return newDate;
    }

    static endOfDay(date) {
        var newDate = new Date(date.getTime());
        newDate.setMilliseconds(0);
        newDate.setSeconds(59);
        newDate.setMinutes(59);
        newDate.setHours(12);
        return newDate;
    }

    static dateMediumString(date, locale) {

        if (date === null) {
            return "";
        }

        if (locale == null) {
            locale = "en";
        }

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(locale, options);
    }

    static dateTimeMediumString(date, locale) {

        if (date === null) {
            return "";
        }

        if (locale == null) {
            locale = "en";
        }

        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString(locale, options);
    }

    static timeMediumString(date, locale) {

        if (date === null) {
            return "";
        }

        if (locale == null) {
            locale = "en";
        }

        const options = { hour: 'numeric', minute: 'numeric' };
        return date.toLocaleTimeString(locale, options);
    }

}

export default DateExt
