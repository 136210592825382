import React, { Component } from 'react';

class Privacy extends Component {
    componentDidMount(){
        document.title = "Vanir • Privacy Policy"
    }

    render() {
        return (
            <div className="card cardFull">
                <h1 id="privacypolicy">Privacy Policy</h1>

                <h2 id="effectivedatemay242018">Effective date: May 24, 2018</h2>

                <p>Frostlight Solutions AB ("us", "we", or "our") operates the website and the Vanir mobile application (the
                    "Service").</p>

                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when
                    you use our Service and the choices you have associated with that data.</p>

                <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and
                    use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms
                    used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>

                <h3 id="1definitions">1. Definitions</h3>

                <ul>
                    <li><em>Service:</em> Service means the website and the Vanir mobile application operated by Frostlight
                        Solutions AB</li>

                    <li><em>Personal Data:</em> Personal Data means data about a living individual who can be identified from
                        those data (or from those and other information either in our possession or likely to come into our
                        possession).</li>

                    <li><em>Usage Data:</em> Usage Data is data collected automatically either generated by the use of the
                        Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>

                    <li><em>Cookies:</em> Cookies are small pieces of data stored on your device (computer or mobile device).</li>

                    <li><em>Data Controller:</em> Data Controller means the natural or legal person who (either alone or
                        jointly or in common with other persons) determines the purposes for which and the manner in which any
                        personal information are, or are to be, processed. <br />
                        For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</li>

                    <li><em>Data Processors (or Service Providers):</em> Data Processor (or Service Provider) means any natural
                        or legal person who processes the data on behalf of the Data Controller. We may use the services of
                        various Service Providers in order to process your data more effectively.</li>

                    <li><em>Data Subject (or User):</em> Data Subject is any living individual who is using our Service and is
                        the subject of Personal Data.</li>
                </ul>

                <h3 id="2informationcollectionanduse">2. Information Collection and Use</h3>

                <ul>
                    <li>We collect several different types of information for various purposes to provide and improve our
                        Service to you.</li>
                </ul>

                <h4 id="21personaldata">2.1 Personal Data</h4>

                <ul>
                    <li>While using our Service, we may ask you to provide us with certain personally identifiable information
                        that can be used to contact or identify you ("Personal Data"). Personally identifiable information may
                        include, but is not limited to:


                        <ul>
                            <li>Email address</li>

                            <li>First name and last name</li>

                            <li>Phone number</li>

                            <li>Address, State, Province, Postal code, City</li>

                            <li>Cookies and Usage Data</li>
                        </ul>
                    </li>

                    <li>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and
                        other information that may be of interest to you. You may opt out of receiving any, or all, of these
                        communications from us by following the unsubscribe link or instructions provided in any email we send
                        or by contacting us.</li>
                </ul>

                <h4 id="22usagedata">2.2 Usage Data</h4>

                <ul>
                    <li>We may also collect information that your browser sends whenever you visit our Service or when you
                        access the Service by or through a mobile device ("Usage Data").</li>

                    <li>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP
                        address), browser type, browser version, the pages of our Service that you visit, the time and date of
                        your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</li>

                    <li>When you access the Service by or through a mobile device, this Usage Data may include information such
                        as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile
                        device, your mobile operating system, the type of mobile Internet browser you use, unique device
                        identifiers and other diagnostic data.</li>
                </ul>

                <h4 id="23locationdata">2.3 Location Data</h4>

                <ul>
                    <li>We may use and store information about your location if you give us permission to do so (“Location
                        Data”). We use this data to provide features of our Service, to improve and customize our Service.</li>

                    <li>You can enable or disable location services when you use our Service at any time, through your device
                        settings.</li>
                </ul>

                <h4 id="24trackingcookiesdata">2.4 Tracking Cookies Data</h4>

                <ul>
                    <li>We use cookies and similar tracking technologies to track the activity on our Service and hold certain
                        information.</li>

                    <li>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies
                        are sent to your browser from a website and stored on your device. Tracking technologies also used are
                        beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</li>

                    <li>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our Service.</li>

                    <li>Examples of Cookies we use:


                        <ul>
                            <li>Session Cookies. We use Session Cookies to operate our Service.</li>

                            <li>Preference Cookies. We use Preference Cookies to remember your preferences and various
                                settings.</li>

                            <li>Security Cookies. We use Security Cookies for security purposes.</li>
                        </ul>
                    </li>
                </ul>

                <h3 id="3useofdata">3. Use of Data</h3>

                <p>Frostlight Solutions AB uses the collected data for various purposes:</p>

                <ul>
                    <li>To provide and maintain our Service</li>

                    <li>To notify you about changes to our Service</li>

                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>

                    <li>To provide customer support</li>

                    <li>To gather analysis or valuable information so that we can improve our Service</li>

                    <li>To monitor the usage of our Service</li>

                    <li>To detect, prevent and address technical issues</li>

                    <li>To provide you with news, special offers and general information about other goods, services and events
                        which we offer that are similar to those that you have already purchased or enquired about unless you
                        have opted not to receive such information</li>
                </ul>

                <h3 id="4legalbasisforprocessingpersonaldataundergeneraldataprotectionregulationgdpr">4. Legal Basis for
                    Processing Personal Data Under General Data Protection Regulation (GDPR)</h3>

                <p>If you are from the European Economic Area (EEA), Frostlight Solutions AB legal basis for collecting and
                    using the personal information described in this Privacy Policy depends on the Personal Data we collect and
                    the specific context in which we collect it.</p>

                <p>Frostlight Solutions AB may process your Personal Data because:</p>

                <ul>
                    <li>We need to perform a contract with you</li>

                    <li>You have given us permission to do so</li>

                    <li>The processing is in our legitimate interests and it's not overridden by your rights</li>

                    <li>For payment processing purposes</li>

                    <li>To comply with the law</li>
                </ul>

                <h3 id="5retentionofdata">5. Retention of Data</h3>

                <p>Frostlight Solutions AB will retain your Personal Data only for as long as is necessary for the purposes set
                    out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required to retain your data to comply with applicable
                    laws), resolve disputes, and enforce our legal agreements and policies.</p>

                <p>Frostlight Solutions AB will also retain Usage Data for internal analysis purposes. Usage Data is generally
                    retained for a shorter period of time, except when this data is used to strengthen the security or to
                    improve the functionality of our Service, or we are legally obligated to retain this data for longer time
                    periods.</p>

                <h3 id="6transferofdata">6. Transfer of Data</h3>

                <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located
                    outside of your state, province, country or other governmental jurisdiction where the data protection laws
                    may differ than those from your jurisdiction.</p>

                <p>If you are located outside Sweden and choose to provide information to us, please note that we transfer the
                    data, including Personal Data, to Sweden and process it there.</p>

                <p>Your consent to this Privacy Policy followed by your submission of such information represents your
                    agreement to that transfer.</p>

                <p>Frostlight Solutions AB will take all steps reasonably necessary to ensure that your data is treated
                    securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place
                    to an organization or a country unless there are adequate controls in place including the security of your
                    data and other personal information.</p>

                <h3 id="7disclosureofdata">7. Disclosure of Data</h3>

                <h4 id="71businesstransaction">7.1 Business Transaction</h4>

                <ul>
                    <li>If Frostlight Solutions AB is involved in a merger, acquisition or asset sale, your Personal Data may
                        be transferred. We will provide notice before your Personal Data is transferred and becomes subject to
                        a different Privacy Policy.</li>
                </ul>

                <h4 id="72legalrequirements">7.2 Legal Requirements</h4>

                <ul>
                    <li>Frostlight Solutions AB may disclose your Personal Data in the good faith belief that such action is
                        necessary to:


                        <ul>
                            <li>To comply with a legal obligation</li>

                            <li>To protect and defend the rights or property of Frostlight Solutions AB</li>

                            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>

                            <li>To protect the personal safety of users of the Service or the public</li>

                            <li>To protect against legal liability</li>
                        </ul>
                    </li>
                </ul>

                <h3 id="8securityofdata">8. Security of Data</h3>

                <p>The security of your data is important to us, but remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
                    protect your Personal Data, we cannot guarantee its absolute security.</p>

                <h3 id="9donottracksignals">9. "Do Not Track" Signals</h3>

                <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to
                    inform websites that you do not want to be tracked.</p>

                <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

                <h3 id="10yourdataprotectionrightsundergeneraldataprotectionregulationgdpr">10. Your Data Protection Rights
                    Under General Data Protection Regulation (GDPR)</h3>

                <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights.
                    Frostlight Solutions AB aims to take reasonable steps to allow you to correct, amend, delete, or limit the
                    use of your Personal Data.</p>

                <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our
                    systems, please contact us.</p>

                <p>In certain circumstances, you have the following data protection rights:</p>

                <ul>
                    <li>The right to access, update or to delete the information we have on you. Whenever made possible, you
                        can access, update or request deletion of your Personal Data directly within your account settings
                        section. If you are unable to perform these actions yourself, please contact us to assist you.</li>

                    <li>The right of rectification. You have the right to have your information rectified if that information
                        is inaccurate or incomplete.</li>

                    <li>The right to object. You have the right to object to our processing of your Personal Data.</li>

                    <li>The right of restriction. You have the right to request that we restrict the processing of your
                        personal information.</li>

                    <li>The right to data portability. You have the right to be provided with a copy of the information we have
                        on you in a structured, machine-readable and commonly used format.</li>

                    <li>The right to withdraw consent. You also have the right to withdraw your consent at any time where
                        Frostlight Solutions AB relied on your consent to process your personal information.</li>
                </ul>

                <p>Please note that we may ask you to verify your identity before responding to such requests.</p>

                <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal
                    Data. For more information, please contact your local data protection authority in the European Economic
                    Area (EEA).</p>

                <h3 id="11serviceproviders">11. Service Providers</h3>

                <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to
                    provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our
                    Service is used.</p>

                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are
                    obligated not to disclose or use it for any other purpose.</p>

                <h4 id="111analytics">11.1 Analytics</h4>

                <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

                <ul>
                    <li>Google Analytics


                        <ul>
                            <li>Google Analytics is a web analytics service offered by Google that tracks and reports website
                                traffic. Google uses the data collected to track and monitor the use of our Service. This data
                                is shared with other Google services. Google may use the collected data to contextualize and
                                personalize the ads of its own advertising network.</li>

                            <li>For more information on the privacy practices of Google, please visit the Google Privacy Terms
                                web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></li>
                        </ul>
                    </li>

                    <li>Firebase


                        <ul>
                            <li>Firebase is analytics service provided by Google Inc.</li>

                            <li>You may opt-out of certain Firebase features through your mobile device settings, such as your
                                device advertising settings or by following the instructions provided by Google in their
                                Privacy Policy: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></li>

                            <li>We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>.
                                For more information on what type of information Firebase collects, please visit please visit
                                the Google Privacy Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></li>
                        </ul>
                    </li>
                </ul>

                <h4 id="112payments">11.2 Payments</h4>

                <ul>
                    <li>We may provide paid products and/or services within the Service. In that case, we use third-party
                        services for payment processing (e.g. payment processors).</li>

                    <li>We will not store or collect your payment card details. That information is provided directly to our
                        third-party payment processors whose use of your personal information is governed by their Privacy
                        Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
                        Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and
                        Discover. PCI-DSS requirements help ensure the secure handling of payment information.</li>

                    <li>The payment processors we work with are:


                        <ul>
                            <li>Apple Store In-App Payments. Their Privacy Policy can be viewed at: <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a></li>

                            <li>Google Play In-App Payments. Their Privacy Policy can be viewed at: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></li>

                            <li>Stripe. Their Privacy Policy can be viewed at: <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a></li>
                        </ul>
                    </li>
                </ul>

                <h3 id="12linkstoothersites">12. Links to Other Sites</h3>

                <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party
                    link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy
                    of every site you visit.</p>

                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
                    third party sites or services.</p>

                <h3 id="13childrensprivacy">13. Children's Privacy</h3>

                <p>Our Service does not address anyone under the age of 18 ("Children").</p>

                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are
                    a parent or guardian and you are aware that your Children has provided us with Personal Data, please
                    contact us. If we become aware that we have collected Personal Data from children without verification of
                    parental consent, we take steps to remove that information from our servers.</p>

                <h3 id="14changestothisprivacypolicy">14. Changes to This Privacy Policy</h3>

                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                    Privacy Policy on this page.</p>

                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
                    effective and update the "effective date" at the top of this Privacy Policy.</p>

                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
                    are effective when they are posted on this page.</p>

                <h3 id="15contactus">15. Contact Us</h3>

                <p>If you have any questions about this Privacy Policy, please contact us:</p>

                <ul>
                    <li>By email: <a href="mailto:info@frostlight.se">info@frostlight.se</a></li>

                    <li>By visiting this page on our website: <a href="https://vanirapp.com">https://vanirapp.com</a></li>
                </ul>
            </div>
        );
    }
}

export default Privacy;
