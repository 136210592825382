import React, { Component } from 'react';
import styles from './TextAreaDetail.module.css';

class TextAreaDetail extends Component {
    constructor(props) {
        super(props);

        this.textArea = React.createRef();
    }

    componentDidMount() {
        this.textResize();
    }

    textChanged = (event) => {
        const { onChange } = this.props;

        this.event = event;
        this.textResize();

        if (onChange != null) {
            onChange(event);
        }
    }

    textResize = () => {
        const textArea = this.textArea.current;
        if (textArea == null) {
            return;
        }

        const offset = textArea.offsetHeight - textArea.clientHeight;
        textArea.style.height = 'auto';
        const height = textArea.scrollHeight + offset;
        textArea.style.height = `${Math.max(20, height)}px`;
    }

    render() {
        const { autofocus, type, name, placeholder, icon, value } = this.props;

        var textarea;
        if (autofocus === "true") {
            textarea = <textarea rows="1" className={styles.title} ref={this.textArea} type={type || "text"} id="text" name={name || "text"} value={value} placeholder={placeholder} onChange={this.textChanged} autoFocus />
        } else {
            textarea = <textarea rows="1" className={styles.title} ref={this.textArea} type={type || "text"} id="text" name={name || "text"} value={value} placeholder={placeholder} onChange={this.textChanged} />
        }

        return (
            <div>
                <div className={styles.row}>
                    <img className={styles.detailIcon} alt="Icon" src={icon}></img>
                    <div className={styles.detail}>
                        {textarea}
                    </div>
                </div>
                <div className={styles.rowSeperator}></div>
            </div>
        );
    }
}

export default TextAreaDetail;
