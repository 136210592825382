import React, { Component } from 'react';
import styles from './AppStoreIcon.module.css';

class AppStoreIcon extends Component {
    render() {
        return (
            <div className={styles.base}>
                <a className={styles.link} href="https://itunes.apple.com/app/apple-store/id1313703532?pt=27287800&ct=Website%20Homepage&mt=8" target="_blank" rel="noopener noreferrer">
                    <img className={styles.badge} src="/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="App Store Badge" />
                </a>
            </div>
        );
    }
}

export default AppStoreIcon;
