import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import MemberListener from '../../Managers/Connections/MemberListener';
import styles from './Header.module.css';
import Avatar from '../Elements/Avatar';
import SettingsDropdown from '../Pages/Auth/SettingsDropdown';

class Header extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.dropdown = React.createRef();

        this.memberListener = new MemberListener(this.database, this.storage, (member) => {

            member.loadUserImage(this.storage, this.functions, null, url => {
                this.setState({userImageUrl: url});
            });

            this.setState({
                name: member.name
            });
        });

        this.state = {
            authFetched: false,
            name: "Loading...",
            userImageUrl: null,
            shouldShowDropdown: false
        };
    }

    componentDidMount() {
        this.memberListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.memberListener.stop();
    }

    showDropdown = (event) => {
        const { shouldShowDropdown } = this.state;

        if (shouldShowDropdown === true) {
            return;
        }

        this.setState({
            shouldShowDropdown: !shouldShowDropdown
        }, () => {

            if (shouldShowDropdown === false) {
                document.addEventListener('click', this.closeDropdown);
            } else {
                document.removeEventListener('click', this.closeDropdown);
            }
        });
    }

    closeDropdown = (event) => {
        const { shouldShowDropdown } = this.state;

        if (shouldShowDropdown === false) {
            return;
        }

        if (this.dropdown && event && this.dropdown.current.contains(event.target)) {
            return;
        }

        this.setState({
            shouldShowDropdown: false
        }, () => {
            document.removeEventListener('click', this.closeDropdown);
        });
    }

    renderNavItem(title, pathname) {
        const name = title.toLowerCase();
        const path = `/${name}`;
        const activeStyle = pathname.startsWith(path) ? styles.active : '';

        return (
            <Link to={path}>
                <div className={`${styles.navIcon} ${activeStyle} ${styles[name]}`} />
                <span className={`${styles.navTitle} ${activeStyle}`}>{title}</span>
            </Link>
        )
    }

    render() {
        const { isLoggedIn, authFetched } = this.props;
        const { userImageUrl, shouldShowDropdown, name } = this.state;

        // If the user is logged in, then attempt to start the listener
        if (isLoggedIn === true) {
            this.memberListener.attemptStartAuth(this.auth.currentUser);
        // Otherwise, invalidtae the listener
        } else {
            
            // Close the dropdown
            this.closeDropdown();

            // Stop the listener
            this.memberListener.stop();
        }

        var avatar;
        if (isLoggedIn === true) {
            avatar = (
                <button className={styles.settings} onClick={this.showDropdown}>
                    <Avatar imageUrl={userImageUrl} style={{ position: "absolute", width: 52, height: 52, right: 0, top: 0, borderWidth: 4 }} />
                </button>
            );
        } else if (authFetched === true) {
            // avatar = <Link className={styles.login} to="/login">Login</Link>;
            avatar = null;
        }

        const pathname = this.props.history.location.pathname;

        return (
            <header className={isLoggedIn === true ? styles.loggedIn : styles.loggedOut}>
                <Link to='/'>
                    <img className={styles.logo} src="/img/logo-vanir-inline.svg" alt="Vanir Logo"></img>
                </Link>
                {isLoggedIn === true && (
                    <nav>
                        {this.renderNavItem('Profile', pathname)}
                        {this.renderNavItem('Connections', pathname)}
                        {this.renderNavItem('Tasks', pathname)}
                        {this.renderNavItem('Chat', pathname)}
                    </nav>
                )}
                <div className={styles.toolBar}>
                    {avatar}
                    <SettingsDropdown reference={this.dropdown} shouldShowDropdown={shouldShowDropdown} name={name} auth={this.auth} history={this.props.history} />
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
