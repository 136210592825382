import React, { Component } from 'react';

class NotFound extends Component {
    componentDidMount() {
        document.title = "Vanir • Page Not Found"
    }

    render() {
        return (
            <div className="card cardMini">
                <h1>404 Page Not Found</h1>
                <p>The specified file was not found on this site.</p>
            </div>
        );
    }
}

export default NotFound;
