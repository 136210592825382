import React, { Component } from 'react';
import styles from './CardImage.module.css';

class Card extends Component {
    render() {
        const { side, imageUrl, editing, cardImageInputChanged, deleteCardImage } = this.props;

        var style;
        var alt = "";
        if (side === "front") {
            style = styles.front;
            alt = "Card Front"
        } else if (side === "back") {
            style = styles.back;
            alt = "Card Back"
        }

        var avitarActionWord = "Click";
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            avitarActionWord = "Tap";
        }

        return (
            <div className={`${styles.cardImage} ${style}`}>
                <img alt={alt} src={imageUrl} />
                {editing === true && (
                    <div className={styles.editing}>
                        <div className={styles.editingLabel}>
                            <label>{avitarActionWord} to Add Image</label>
                        </div>
                        <input className={styles.editing} type="file" id={`cardImage-${side}`} name={`cardImage-${side}`} accept="image/jpg, image/jpeg" onChange={() => {cardImageInputChanged(side)}} />
                        {imageUrl !== "" && imageUrl !== '/img/placeholder-card-business-front.svg' && imageUrl !== '/img/placeholder-card-business-back.svg' && (
                            <div className={styles.clear} onClick={() => {deleteCardImage(side)}} />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default Card;
