import React, { Component } from 'react';
// import styles from './Welcome.module.css';
import AppStoreIcon from '../Elements/AppStoreIcon';

class Welcome extends Component {
    componentDidMount() {
        document.title = "Vanir"
    }

    render() {
        return (
            <div className="card cardMini">
                <h1>Download Now!</h1>
                <AppStoreIcon />
            </div>
        );
    }
}

export default Welcome;
