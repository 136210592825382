import React, { Component } from 'react';
import styles from './ModalCardView.module.css';

class ModalCardView extends Component {
    constructor(props) {
        super(props);

        this.card = React.createRef();
    }

    closeFromBase = (event) => {

        if (this.card && this.card.current.contains(event.target)) {
            return;
        }

        this.close();
    }

    close = () => {
        const { close } = this.props;

        if (close == null) {
            return;
        }

        close();

        if (this.card) {
            this.card.current.scrollTo(0, 0);
        }
    }

    render() {
        const { component: Component, visible } = this.props;
        const visibleStyle = visible === true ? styles.modalBaseVisible : styles.modalBaseInvisible;

        return (
            <div className={`${styles.modalBase} ${visibleStyle}`} onClick={this.closeFromBase}>
                <div className={styles.modalCard} ref={this.card}>
                    <div className={styles.close} onClick={this.close} />
                    <div className={styles.content}>
                        {Component != null && (
                            <Component {...this.props} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalCardView
