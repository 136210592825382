import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './ConnectionCard.module.css';
import Avatar from '../../Elements/Avatar';
import ShareButton from '../../Elements/ShareButton';

class ConnectionCard extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.state = {
            userImageUrl: null
        };
    }

    shareButtonClicked = () => {
        const { history, connection } = this.props;

        if (connection != null) {
            history.push(`/connections/${connection.identifier}/share`)
        }
    }

    render() {
        const { connection, sortType, showShareButton } = this.props;
        var { userImageUrl } = this.state;

        if (this.connectionIdentifier != null && this.connectionIdentifier !== connection.identifier) {
            userImageUrl = null;
        }

        if (connection != null) {
            this.connectionIdentifier = connection.identifier;
        }

        if (connection != null && userImageUrl == null) {
            connection.loadUserImage(this.storage, this.functions, this.auth.currentUser, url => {
                this.setState({userImageUrl: url});
            });
        }

        return (
            <div className={`card cardHover ${styles.row}`}>
                {connection != null ? (
                    <Link className={styles.link} to={`/connections/${connection.identifier}`}>
                        <div className={styles.image}>
                            <Avatar imageUrl={connection != null ? userImageUrl : null} style={{ width: 36, height: 36, marginTop: 4 }} />
                        </div>
                        <div className={styles.name}>{connection != null ? connection.renderName(sortType) : "Loading..."}</div>
                    </Link>
                ) : (
                    <div className={styles.link}>
                        <div className={styles.image}>
                            <Avatar imageUrl={null} style={{ width: 36, height: 36, marginTop: 4 }} />
                        </div>
                        <div className={styles.name}>{"Loading..."}</div>
                    </div>
                )}
                {showShareButton === true && (
                    <div className={styles.share}>
                        <ShareButton memberType={connection.type} disabled={connection.type !== 'member'} onClick={this.shareButtonClicked} to={`/connections/${connection.identifier}/share`} />
                    </div>
                )}
            </div>
        )
    }

}

export default ConnectionCard
