import React, { Component } from 'react';
import styles from './ShareButton.module.css';

class ShareButton extends Component {
    render() {
        const { memberType, buttonType, disabled, onClick } = this.props;
        const disabledStyle = disabled === true ? styles.disabled : '';
        const icon = memberType === "member" ? '/img/white-share.svg' : '/img/white-invite.svg';
        const style = memberType === "member" ? styles.member : styles.placeholder;

        return (
            <button className={`${styles.button} ${style} ${disabledStyle}`} type={buttonType} onClick={disabled ? null : onClick}>
                <div className={styles.icon} style={{backgroundImage: `url(${icon})`}} />
            </button>
        )
    }
}

export default ShareButton
