import React, { Component } from 'react';
// import styles from './ComingSoon.module.css';
import AppStoreIcon from '../Elements/AppStoreIcon';

class ComingSoon extends Component {
    componentDidMount() {
        document.title = "Vanir"
    }

    render() {
        return (
            <div className="card cardMini">
                <h1>Coming Soon!</h1>
                <p>To get this functionality,<br/>please download the app:</p>
                <AppStoreIcon />
            </div>
        );
    }
}

export default ComingSoon;
