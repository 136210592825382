import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker';
import styles from './TaskDetail.module.css';
import CheckmarkButton from '../../Elements/CheckmarkButton';
import Button from '../../Elements/Button';
import Task from '../../../Models/Task';
import Detail from '../../Elements/Detail';
import DateExt from '../../../Extensions/DateExt';
import ConnectionCard from '../../Views/Connections/ConnectionCard';
import Connection from '../../../Models/Connection';
import ModalCardView from '../../Elements/ModalCardView';
import ConnectionSelector from '../Connections/ConnectionSelector';

class TaskDetail extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.identifier = props.match.params.id;

        if (this.identifier === "new") {

            const connectionId = props.history.location.state.connectionId;

            const subsection = props.history.location.state.subsection;
            var due = null;
            if (subsection === "Due") {
                due = new Date();

                // Set the time to 9am
                due.setMilliseconds(0);
                due.setSeconds(0);
                due.setMinutes(0);
                due.setHours(9);
                
                // Change the date to the day after and set to due
                due = DateExt.addDays(due, 1);
            }

            this.task = new Task({connectionIdentifier: connectionId});
            this.task.due = due;
        }

        this.nameTextArea = React.createRef();

        this.state = {
            uid: null,
            taskUpdated: 0,
            isSaving: false,
            saveDisabled: true,
            dateEditing: false,
            shouldShowConnectionView: false
        };
    }

    componentDidMount() {
        document.title = "Vanir • Task Detail";

        if (this.auth.currentUser) {
            const uid = this.auth.currentUser.uid;
            this.setState({uid: uid});

            if (this.identifier === "new") {
                this.nameTextArea.current.focus();
                this.fetchConnection(uid);
                return;
            }

            Task.get(this.database, uid, this.identifier)
                .then(task => {

                    if (task == null) {
                        return;
                    }

                    this.task = task;
                    this.setState({
                        taskUpdated: this.state.taskUpdated+1,
                        saveDisabled: task.name === ""
                    });

                    this.nameResize();
                    this.fetchConnection(uid);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    fetchConnection(uid) {
        const connectionIdentifier = this.task.connectionIdentifier;
        if (connectionIdentifier != null && connectionIdentifier !== "") {

            Connection.get(this.database, uid, connectionIdentifier)
                .then(connection => {

                    if (connection == null) {
                        this.connection = false;
                    } else {
                        this.connection = connection;
                    }
                    
                    this.setState({taskUpdated: this.state.taskUpdated+1});
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    taskChecked = () => {
        const { uid } = this.state;
        var task = this.task;
        if (uid == null || task == null) {
            return;
        }

        const completed = !task.completed;
        this.task.completed = completed;
        this.setState({taskUpdated: this.state.taskUpdated+1});

        // Do after 300ms to update after animation
        setTimeout(() => {
            task.markComplete(this.database, uid, completed)
                .catch(err => {
                    console.error(err);
                });

        }, 300);
    }

    nameChanged = (event) => {
        this.task.name = event.target.value;

        this.nameResize();

        this.setState({
            taskUpdated: this.state.taskUpdated+1,
            saveDisabled: this.task.name === ""
        });
    }

    nameResize = () => {
        const textArea = this.nameTextArea.current;
        if (textArea == null) {
            return;
        }

        const offset = textArea.offsetHeight - textArea.clientHeight;
        textArea.style.height = 'auto';
        const height = textArea.scrollHeight + offset;
        textArea.style.height = `${height}px`;
    }

    setConnection = () => {
        this.setState({shouldShowConnectionView: true});
    }

    selectedConnection = (connection) => {

        this.connection = connection;
        if (connection != null) {
            this.task.connectionIdentifier = connection.identifier;
        } else {
            this.task.connectionIdentifier = null;
        }

        this.setState({
            taskUpdated: this.state.taskUpdated+1,
            shouldShowConnectionView: false
        });
    }

    closeConnectionView = () => {
        this.setState({shouldShowConnectionView: false});
    }

    dueClicked = () => {
        const { dateEditing } = this.state;
        this.setState({dateEditing: !dateEditing});
    }

    dateChanged = (date) => {
        
        if (this.task == null) {
            return;
        }

        this.task.due = date;

        this.setState({taskUpdated: this.state.taskUpdated+1});
    }

    saveTask = () => {
        const { uid } = this.state;
        var task = this.task;
        if (uid == null || task == null) {
            return;
        }

        this.setState({
            isSaving: true,
            dateEditing: false
        });

        if (task.identifier == null) {
            task.create(this.database, uid)
                .then(taskId => {

                    this.props.history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            task.save(this.database, uid)
                .then(() => {
                    this.setState({
                        isSaving: false
                    });

                    this.props.history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    deleteTask = () => {
        const { uid } = this.state;
        var task = this.task;
        if (uid == null || task == null) {
            return;
        }

        if (window.confirm("Are you sure you want to delete this task?")) { 
            
            this.setState({
                isDeleting: true
            });

            task.delete(this.database, uid)
                .then(() => {
                    this.setState({
                        isDeleting: false
                    });

                    this.props.history.goBack();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    renderContent() {
        const { firebase, history } = this.props;
        const { isSaving, isDeleting, saveDisabled, dateEditing, shouldShowConnectionView } = this.state;
        const task = this.task;

        const due = (task != null && task.due != null) ? DateExt.dateTimeMediumString(task.due, navigator.language) : "No Date Set";

        if (task == null) {
            return <div className={styles.loading}>Loading...</div>
        } else {
            return (
                <div>
                    {shouldShowConnectionView === true && (
                        <ModalCardView component={ConnectionSelector} visible={shouldShowConnectionView} firebase={firebase} history={history} close={this.closeConnectionView} selected={this.selectedConnection} actionClicked={() => {this.selectedConnection(null)}} />
                    )}
                    <div className={`card ${styles.task}`}>
                        <div className={styles.titleRow}>
                            <div className={styles.checkmark}>
                                <CheckmarkButton identifier={task.identifier} complete={task.completed} overdue={task.overdue} check={this.taskChecked} />
                            </div>
                            <textarea rows="1" className={`${styles.title} ${task.completed ? styles.titleCompleted : ''}`} ref={this.nameTextArea} type="text" id="name" name="name" value={task.name} placeholder="Task Name" onChange={this.nameChanged} />
                        </div>
                        {dateEditing === false ? (
                            <div className={styles.dueContainer} onClick={this.dueClicked}>
                                <Detail title="Due" value={due} icon="/img/grey-calendar.svg" hideSeperator={true} />
                            </div>
                        ) : (
                            <div className={styles.pickerContainer}>
                                <img className={styles.pickerIcon} alt="Icon" src="/img/grey-calendar.svg"></img>
                                <DateTimePicker className={styles.pickerDue} value={task.due} onChange={this.dateChanged} disableClock={true} minDetail={"year"} calendarIcon={null} isCalendarOpen={true} />
                                <div className={styles.pickerSpacer} onClick={this.dueClicked} />
                            </div>
                        )}
                        <div className={styles.buttons}>
                            {task.connectionIdentifier != null && this.connection !== false ? (
                                <div>
                                    <div className={styles.connection}>
                                        <ConnectionCard connection={this.connection} firebase={firebase} history={history} />
                                    </div>
                                    <Button title="Edit Connection" buttonStyle="whiteDefault" onClick={this.setConnection} />
                                </div>
                            ) : (
                                <Button title="Add Connection" onClick={this.setConnection} />
                            )}
                            <Button title="Save" disabled={saveDisabled} onClick={this.saveTask} loading={isSaving} />
                            {task.identifier != null && (
                                <Button title="Delete" buttonStyle="whiteDestructive" onClick={this.deleteTask} loading={isDeleting} />
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={`baseContainer baseContainerMargin`}>
                {this.renderContent()}
            </div>
        )
    }
}

export default TaskDetail
