import DateExt from '../Extensions/DateExt';

class Task {
    constructor(document, identifier) {

        if (document == null) {
            document = {};
        }

        this.identifier = identifier;
        this.order = document.order || 0;
        this.connectionIdentifier = document.connectionIdentifier || null;
        this.name = document.name || "";
        this.completed = document.completed || false;
        if (document.due != null) {
            this.due = document.due.toDate();
            const today = DateExt.stripTime(new Date());
            this.overdue = (this.due < today);
        } else {
            this.due = null;
            this.overdue = false;
        }

        if (document.created != null) {
            this.created = document.created.toDate();
        } else {
            this.created = new Date();
        }
        
        if (document.updated != null) {
            this.updated = document.updated.toDate();
        } else {
            this.updated = this.created;
        }
    }

    firestoreDoc = () => {

        const date = new Date();
        return {
            order: this.order || 0,
            connectionIdentifier: this.connectionIdentifier || null,
            name: this.name || "",
            completed: this.completed || false,
            due: this.due || null,
            created: this.created || date,
            updated: date
        };
    }

    static create(database, uid, task) {
        return database.collection(`users/${uid}/tasks`)
            .add(task.firestoreDoc())
            .then((docRef) => {
                return docRef.id;
            });
    }

    create = (database, uid) => {
        return Task.create(database, uid, this);
    }

    static save(database, uid, task) {
        const identifier = task.identifier;
        return database.doc(`users/${uid}/tasks/${identifier}`)
            .update(task.firestoreDoc());
    }

    save = (database, uid) => {
        return Task.save(database, uid, this);
    }

    static markComplete(database, identifier, uid, complete) {
        return database.doc(`users/${uid}/tasks/${identifier}`)
            .update({
                completed: complete,
                updated: new Date()
            });
    }

    markComplete = (database, uid, complete) => {
        return Task.markComplete(database, this.identifier, uid, complete);
    }

    static delete(database, identifier, uid) {
        return database.doc(`users/${uid}/tasks/${identifier}`)
            .delete();
    }

    delete = (database, uid) => {
        return Task.delete(database, this.identifier, uid);
    }

    static get(database, uid, identifier) {
        return database.doc(`users/${uid}/tasks/${identifier}`)
            .get()
            .then(snapshot => {
                if (snapshot.exists === true) {
                    return new Task(snapshot.data(), snapshot.id);
                }
            });
    }
}

export default Task
