import React, { Component } from 'react';
import styles from './ConnectionSelector.module.css';
import ConnectionsListener from '../../../Managers/Connections/ConnectionsListener'
import SearchBar from '../../Elements/SearchBar';
import ConnectionRow from '../../Views/Connections/ConnectionRow';
import Button from '../../Elements/Button';
import EmptyDataView from '../../Elements/EmptyDataView';

class ConnectionSelector extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.connectionsListener = new ConnectionsListener(this.database, this.storage, null, (connections) => {
            this.setState({
                fetchedConnections: connections
            });
        });

        this.state = {
            fetchedConnections: [],
            filteredConnections: null
        };
    }

    componentDidMount() {
        this.connectionsListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.connectionsListener.stop();
    }

    connectionSearchDidChange = (event) => {
        const searchText = event.target.value.toLowerCase() || "";
        const { fetchedConnections } = this.state;

        const filteredConnections = this.connectionsListener.filterConnections(searchText, fetchedConnections);
        this.setState({filteredConnections: filteredConnections});
    }

    renderSectionGroup(group) {
        return (
            <div key={group.key} className={styles.container}>
                <div className={styles.section}>
                    {this.renderRows(group.value)}
                </div>
            </div>
        );
    }

    renderRows(connections) {
        const { firebase, history, selected } = this.props;

        return connections.map(connection => {
            return (
                <div className={styles.row} key={connection.identifier}>
                    <ConnectionRow connection={connection} sortType={this.connectionsListener.sortType} firebase={firebase} history={history} selected={() => {selected(connection)}} />
                </div>
            )
        })
    }

    render() {
        const { actionClicked } = this.props;
        const { fetchedConnections, filteredConnections } = this.state;
        const connections = filteredConnections != null ? filteredConnections : fetchedConnections;

        return (
            <div className={`baseContainer baseContainerMargin`}>
                <div className={styles.contentOffset}>
                    <SearchBar name="ConnectionSearch" placeholder="Search (use # to search for tags)" focusOnMount={true} onChange={this.connectionSearchDidChange} />
                    <div className={styles.action}>
                        <Button title="No Connection" onClick={actionClicked} />
                    </div>
                </div>
                {
                    connections.map(group => {
                        return this.renderSectionGroup(group);
                    })
                }
                {this.connectionsListener.loadedInitially === true && connections.length === 0 && (
                    <EmptyDataView icon={"empty_connections.svg"} title={"You don't have any connections yet."} type={'light'} />
                )}
            </div>
        )
    }
}

export default ConnectionSelector
