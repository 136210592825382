import DateExt from '../Extensions/DateExt';
import Task from '../Models/Task';

class TasksListener {

    listener = null;
    _sortType = "incompleteDue";
    uid = null;
    connectionId = null;
    loadedInitially = false;

    constructor(database, storage, connectionId, sortType, dataUpdated) {
        this.database = database;
        this.storage = storage;
        this.connectionId = connectionId;
        this.dataUpdated = dataUpdated;

        if (sortType != null) {
            this._sortType = sortType;
        }
    }

    setSortType(sortType) {
        const uid = this.uid;
        const connectionId = this.connectionId;
        this.stop();
        this.loadedInitially = false;
        this.dataUpdated([]);
        this._sortType = sortType;
        this.connectionId = connectionId;
        this.attemptStart(uid);
    }
    
    attemptStartAuth(currentUser) {

        if (currentUser == null) {
            return;
        }

        const uid = currentUser.uid;
        this.attemptStart(uid);
    }

    attemptStart(uid) {

        if (this.listener != null) {
            return;
        }

        this.uid = uid;
        var query = this.database.collection(`users/${uid}/tasks`);
        if (this._sortType === "incompleteDue") {
            query = query.orderBy("due").where("completed", "==", false).where("due", "<", DateExt.distantFuture());
        } else if (this._sortType === "completedDue") {
            query = query.orderBy("due", "desc").where("completed", "==", true).where("due", "<", DateExt.distantFuture());
        } else if (this._sortType === "incompleteList") {
            query = query.orderBy("created").where("completed", "==", false).where("due", "==", null);
        } else if (this._sortType === "completedList") {
            query = query.orderBy("created").where("completed", "==", true).where("due", "==", null);
        }

        if (this.connectionId != null && this.connectionId !== "") {
            query = query.where("connectionIdentifier", "==", this.connectionId)
        }

        this.listener = query.onSnapshot((snapshot) => {
            var tasks = [];
            snapshot.forEach(doc => {
                if (doc.exists === true) {

                    const task = new Task(doc.data(), doc.id);
                    tasks.push(task);
                }
            });

            const sortedTasks = this.sortTasks(tasks);

            this.loadedInitially = true;

            if (this.dataUpdated) {
                this.dataUpdated(sortedTasks);
            }
        });
    }

    sortTasks(tasks) {
        const sortType = this._sortType;

        const today = DateExt.stripTime(new Date());
        const nextWeek = DateExt.endOfDay(DateExt.addDays(new Date(), 7));

        const groupedTasks = tasks.reduce((acc, value) => {
            
            if (sortType === "incompleteDue") {

                if (value.due == null) {
                    return acc;
                }

                const due = DateExt.stripTime(value.due);

                let date;
                if (DateExt.isToday(due) === true) {
                    date = due;
                } else if (due < today) {
                    date = DateExt.distantPast();
                } else if (due < nextWeek) {
                    date = nextWeek;
                } else {
                    date = DateExt.distantFuture();
                }

                (acc[date] = acc[date] || []).push(value);

            } else if (sortType === "completedDue") {

                if (value.due == null) {
                    return acc;
                }

                const due = DateExt.stripTime(value.due);
                (acc[due] = acc[due] || []).push(value);

            } else if (sortType === "incompleteList" || sortType === "completedList") {
                (acc[value.created] = acc[value.created] || []).push(value);
            }

            return acc;
        }, {});

        const keys = Object.keys(groupedTasks).map(dateString => {
            return new Date(dateString);
        })

        var sortedKeys = keys.sort((firstEl, secondEl) => {

            if (sortType === "incompleteDue") {
                return firstEl.getTime() - secondEl.getTime();
            } else if (sortType === "completedDue") {

                const firstTime = firstEl.getTime();
                const secondTime = secondEl.getTime();

                if (firstTime < secondTime) {
                    return 1;
                } else if (firstTime > secondTime) {
                    return -1;
                }
                return 0;

            } else {
                return 0;
            }
        });

        if (sortType === "incompleteDue") {

            const index = sortedKeys.findIndex(DateExt.isToday);
            if (index > -1) {
                sortedKeys.splice(index, 1);
                sortedKeys.splice(0, 0, today);
            }
        }

        var sortedTasks = [];
        sortedKeys.forEach(key => {
            sortedTasks.push(
                {key: key, value: groupedTasks[key]}
            );
        });

        return sortedTasks;
    }

    stop() {
        if (this.listener == null) {
            return;
        }

        this.listener();
        this.listener = null;
        this.uid = null;
        this.connectionId = null;
    }

}

export default TasksListener
