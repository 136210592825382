import React, { Component } from 'react';
import styles from './ConnectionRow.module.css';
import Avatar from '../../Elements/Avatar';

class ConnectionCard extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.state = {
            userImageUrl: null
        };
    }

    render() {
        const { connection, sortType, selected } = this.props;
        const { userImageUrl } = this.state;

        if (connection != null && userImageUrl == null) {
            connection.loadUserImage(this.storage, this.functions, this.auth.currentUser, url => {
                this.setState({userImageUrl: url});
            });
        }

        return (
            <div>
                <div className={styles.row} onClick={selected}>
                    <div className={styles.image}>
                        <Avatar imageUrl={connection != null ? userImageUrl : null} style={{ width: 36, height: 36, marginTop: 4 }} />
                    </div>
                    <div className={styles.name}>{connection != null ? connection.renderName(sortType) : "Loading..."}</div>
                </div>
                <div className={styles.rowSeperator} />
            </div>
        )
    }

}

export default ConnectionCard
