import React, { Component } from 'react';
import styles from './Button.module.css';
import { BubbleLoader } from 'react-css-loaders';

class Button extends Component {
    render() {
        const { className, buttonStyle, type, disabled, loading, title, onClick } = this.props;
        const disabledStyle = disabled === true ? styles.disabled : '';

        var buttonCSSStyle;
        var loadingColor;
        if (buttonStyle === "whiteDefault") {
            buttonCSSStyle = styles.whiteDefault;
            loadingColor = "#83C9BC";
        } else if (buttonStyle === "whiteDestructive") {
            buttonCSSStyle = styles.whiteDestructive;
            loadingColor = "#83C9BC";
        } else {
            buttonCSSStyle = styles.default;
            loadingColor = "#ffffff";
        }

        var buttonType = "button";
        if (type != null) {
            buttonType = type;
        }

        var buttonContent;
        if (loading) {
            buttonContent = <BubbleLoader style={{margin: '-22px auto'}} color={loadingColor} duration="1" size="6" />;
        } else {
            buttonContent = title.toUpperCase();
        }

        return (
            <div className={styles.roundedButton}>
                <button className={`${className || styles.defaultButton} ${styles.button} ${buttonCSSStyle} ${disabledStyle}`} type={buttonType} onClick={disabled ? null : onClick}>
                    { buttonContent }
                </button>
            </div>
        );
    }
}

export default Button;
