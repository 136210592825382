import React, { Component } from 'react';
import styles from './CardView.module.css';
import CardImageController from './CardImageController'
import Detail from '../../Elements/Detail'
import InputDetail from '../../Elements/InputDetail';
import Button from '../../Elements/Button';
import Card from '../../../Models/Card';

class CardView extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.resetCard();

        this.state = {
            isSaving: false,
            isDeleting: false,
            editing: this.card.isNew() === true,
            cardEdited: 0
        }
    }

    resetCard = () => {
        this.card = new Card(this.props.card.firestoreDoc(), this.props.card.identifier);
    }

    editButtonClicked = () => {
        this.setState({editing: true});
    }

    cancelButtonClicked = () => {
        this.resetCard();

        this.setState({editing: false});
    }

    itemChanged = (event, field, index) => {
        const { cardEdited } = this.state;

        const item = event.target.value;

        if (index != null) {
            this.card[field][index].value = item;
        } else {
            this.card[field] = item;
        }

        this.setState({memberEdited: cardEdited+1});
    }

    addItem = (event, field) => {
        const { cardEdited } = this.state;

        var item = {
            type: 1,
            value: ""
        }
        this.card[field].push(item);

        this.setState({memberEdited: cardEdited+1});
    }

    clearItem = (event, field, index) => {
        const { cardEdited } = this.state;

        this.card[field].splice(index, 1);

        this.setState({memberEdited: cardEdited+1});
    }

    saveCard = () => {
        const { connection } = this.props;
        const currentUser = this.auth.currentUser;
        const card = this.card;
        if (currentUser == null || card == null) {
            return;
        }

        this.setState({isSaving: true});

        const frontImageFile = document.querySelector(`#cardImage-front`).files[0];
        const backImageFile = document.querySelector(`#cardImage-back`).files[0];

        card.save(this.database, this.storage, connection, currentUser, [frontImageFile, backImageFile])
            .then(() => {
                this.setState({editing: false, isSaving: false})
            })
            .catch(err => {
                console.error(err);
            });
    }

    deleteCard = () => {
        const { connection } = this.props;
        const currentUser = this.auth.currentUser;
        const card = this.card;
        if (currentUser == null || card == null) {
            return;
        }

        if (window.confirm("Are you sure you want to delete this card?")) { 
            
            this.setState({
                isDeleting: true
            });

            card.delete(this.database, connection, currentUser)
                .then(() => {
                    this.setState({
                        isDeleting: false
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    renderCardDetails(defaultTitle, items, prefix, icon, favoriteItems = [], editing, key) {
        const { showFavorites } = this.props;

        return items.map((detail, index) => {

            var title = defaultTitle;
            if (detail.type === 0 && detail.customLabel != null && detail.customLabel !== "") {
                title = detail.customLabel;
            }

            if (editing === true) {
                return <InputDetail key={`${index}-${key}`} placeholder={title} value={detail.value} prefix={prefix} icon={icon} accessoryIcon={'/img/grey-close.svg'} onAccessoryClick={(event) => { this.clearItem(event, key, index) }} onChange={(event) => { this.itemChanged(event, key, index) }} />
            } else {

                var accessoryIcon = null;
                if (showFavorites === true) {
                    accessoryIcon = favoriteItems.includes(detail.value) ? '/img/green-star-active.svg' : null;
                }

                return <Detail key={`${index}-${key}`} title={title} value={detail.value} prefix={prefix} icon={icon} accessoryIcon={accessoryIcon} />
            }
        });
    }

    renderEditing() {
        const { firebase, connection, userId } = this.props;
        const { isSaving, isDeleting } = this.state;
        const card = this.card;

        var cardType = card.type;
        if (cardType === "business") {
            cardType = "Business"
        } else if (cardType === "personal") {
            cardType = "Personal"
        }

        return (
            <div>
                <h3 className={styles.cardTitle}>{cardType}</h3>
                <div className={`card ${styles.profileCard}`}>
                    <CardImageController firebase={firebase} connection={connection} userId={userId} cardId={card.identifier} cardImageFilenames={card.cardImageFilenames} card={card} editing={true} />
                    <InputDetail placeholder="Company" value={card.companyName} icon="/img/grey-group.svg" onChange={(event) => { this.itemChanged(event, 'companyName') }} />
                    <InputDetail placeholder="Title" value={card.companyTitle} icon="/img/grey-toolbox.svg" onChange={(event) => { this.itemChanged(event, 'companyTitle') }} />
                    {this.renderCardDetails("Email", card.emails, null, "/img/grey-email.svg", null, true, 'emails')}
                    <Detail value={"Add Email"} icon={'/img/add-button-circle.svg'} onClick={(event) => {this.addItem(event, 'emails')}} />
                    {this.renderCardDetails("Phone Numbers", card.phoneNumbers, null, "/img/grey-phone.svg", null, true, 'phoneNumbers')}
                    <Detail value={"Add Phone Number"} icon={'/img/add-button-circle.svg'} onClick={(event) => {this.addItem(event, 'phoneNumbers')}} />
                    {this.renderCardDetails("Websites", card.websites, null, "/img/grey-www.svg", null, true, 'websites')}
                    <Detail value={"Add Website"} icon={'/img/add-button-circle.svg'} onClick={(event) => {this.addItem(event, 'websites')}} />
                    {this.renderCardDetails("Usernames", card.usernames, null, "/img/grey-user.svg", null, true, 'usernames')}
                    <Detail value={"Add Username"} icon={'/img/add-button-circle.svg'} onClick={(event) => {this.addItem(event, 'usernames')}} />
                    {this.renderCardDetails("Addresses", card.addresses, null, "/img/grey-location.svg", null, true, 'addresses')}
                    <Detail value={"Add Address"} icon={'/img/add-button-circle.svg'} onClick={(event) => {this.addItem(event, 'addresses')}} />
                    <div className={styles.buttons}>
                        <Button className={styles.button} buttonStyle="whiteDefault" title="Cancel" disabled={isSaving || isDeleting} onClick={this.cancelButtonClicked} />
                        <Button className={styles.button} buttonStyle="whiteDestructive" title="Delete" disabled={isSaving} loading={isDeleting} onClick={this.deleteCard} />
                        <Button className={styles.button} title="Save" disabled={isDeleting} loading={isSaving} onClick={this.saveCard} />
                    </div>
                </div>
            </div>
        );
    }

    renderView() {
        const { firebase, connection, userId, editable } = this.props;
        const card = this.card;
        const favoriteItems = card.favoriteItems || {};

        var cardType = card.type;
        if (cardType === "business") {
            cardType = "Business"
        } else if (cardType === "personal") {
            cardType = "Personal"
        }

        return (
            <div>
                <h3 className={styles.cardTitle}>{cardType}</h3>
                <div className={`card ${styles.profileCard}`}>
                    <CardImageController firebase={firebase} connection={connection} userId={userId} cardId={card.identifier} cardImageFilenames={card.cardImageFilenames} />
                    <Detail title="Company" value={card.companyName} icon="/img/grey-group.svg" />
                    <Detail title="Title" value={card.companyTitle} icon="/img/grey-toolbox.svg" />
                    {this.renderCardDetails("Email", card.emails, "mailto:", "/img/grey-email.svg", favoriteItems.emails)}
                    {this.renderCardDetails("Phone Numbers", card.phoneNumbers, "tel:", "/img/grey-phone.svg", favoriteItems.phoneNumbers)}
                    {this.renderCardDetails("Websites", card.websites, "https://", "/img/grey-www.svg")}
                    {this.renderCardDetails("Usernames", card.usernames, null, "/img/grey-user.svg")}
                    {this.renderCardDetails("Addresses", card.addresses, "http://maps.apple.com/?q=", "/img/grey-location.svg")}
                    {editable === true && (
                        <div className={styles.buttons}>
                            <Button className={styles.button} title="Edit" onClick={this.editButtonClicked} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { editing } = this.state;

        if (editing === true) {
            return this.renderEditing();
        } else {
            return this.renderView();
        }
    }
}

export default CardView;
