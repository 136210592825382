import Connection from './Connection';

class Member extends Connection {
    
    constructor(document, identifier) {
        super(document, identifier);

        this.isAdmin = document.isAdmin || false;
    }

    firestoreDoc = () => {
        var doc = super.document();
        doc.isAdmin = this.isAdmin || false;
        return doc;
    }

    static get(database, uid) {
        return database.doc(`users/${uid}`)
            .get()
            .then(snapshot => {
                if (snapshot.exists === true) {
                    return new Member(snapshot.data(), snapshot.id);
                }
            });
    }
    
}

export default Member
