import React, { Component } from 'react';
import styles from './SelectorBar.module.css';

class SelectorBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: props.selectedIndex || 0
        };
    }

    itemSelected = (index) => {
        this.setState({
            selectedIndex: index
        });

        this.props.selectorBarChanged(index);
    }

    render() {
        const { className, itemTitles } = this.props;
        const { selectedIndex } = this.state;

        return (
            <div className={`${className || styles.default} ${styles.base}`}>
                {
                    itemTitles.map((title, index) => {
                        const selectedStlye = index === selectedIndex ? styles.itemSelected : "";
                        return <div key={index} className={`${styles.item} ${selectedStlye}`} onClick={()=>{this.itemSelected(index)}}>{title}</div>
                    })
                }
            </div>
        );
    }
}

export default SelectorBar
