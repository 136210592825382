import React, { Component } from 'react';
import styles from './Connections.module.css';
import ConnectionsListener from '../../../Managers/Connections/ConnectionsListener'
import Connection from '../../../Models/Connection'
import DateExt from '../../../Extensions/DateExt';
import ConnectionCard from '../../Views/Connections/ConnectionCard'
import SearchBar from '../../Elements/SearchBar';
import EmptyDataView from '../../Elements/EmptyDataView';
import AddButton from '../../Elements/AddButton';

class Connections extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();

        this.connectionsListener = new ConnectionsListener(this.database, this.storage, null, (connections) => {
            this.setState({
                fetchedConnections: connections
            });
        });

        this.state = {
            fetchedConnections: [],
            filteredConnections: null,
            shouldShowFilterDropdown: false
        };
    }

    componentDidMount() {
        document.title = "Vanir • Connections";

        this.connectionsListener.attemptStartAuth(this.auth.currentUser);
    }

    componentWillUnmount() {
        this.connectionsListener.stop();
    }

    connectionSearchDidChange = (event) => {
        const searchText = event.target.value.toLowerCase() || "";
        const { fetchedConnections } = this.state;

        const filteredConnections = this.connectionsListener.filterConnections(searchText, fetchedConnections);
        this.setState({filteredConnections: filteredConnections});
    }

    showFilterDropdown = () => {
        const { shouldShowFilterDropdown } = this.state;

        this.setState({
            shouldShowFilterDropdown: !shouldShowFilterDropdown
        }, () => {

            if (shouldShowFilterDropdown === false) {
                document.addEventListener('click', this.closeFilterDropdown);
            } else {
                document.removeEventListener('click', this.closeFilterDropdown);
            }
        });
    }

    closeFilterDropdown = () => {

        this.setState({
            shouldShowFilterDropdown: false
        }, () => {
            document.removeEventListener('click', this.closeFilterDropdown);
        });
    }

    filterSelected = (sortType) => {

        if (sortType === this.connectionsListener.sortType) {
            return;
        }

        this.connectionsListener.setSortType(sortType);

        this.setState({
            shouldShowFilterDropdown: false
        }, () => {
            document.removeEventListener('click', this.closeFilterDropdown);
        });
    }

    createPlaceholder = () => {
        const { history } = this.props;

        const placeholder = new Connection();
        placeholder.create(this.database, this.auth.currentUser)
            .then(identifier => {
                history.push(`/connections/${identifier}`);
            })
            .catch(err => {
                console.error(err);
            });
    }

    renderSectionGroup(group) {

        const key = group.key;
        const sortType = this.connectionsListener.sortType;

        var headerText;
        if (sortType === Connection.SortType.firstName || sortType === Connection.SortType.lastName) {
            headerText = key;
        } else if (sortType === Connection.SortType.rating) {
            
            if (key === Connection.Rating.none) {
                headerText = "☆ No Rating";
            } else if (key === Connection.Rating.normal) {
                headerText = "★ Normal";
            } else if (key === Connection.Rating.important) {
                headerText = "★★ Important";
            } else if (key === Connection.Rating.veryImportant) {
                headerText = "★★★ Very Important";
            } else {
                headerText = "";
            }

        } else if (sortType === Connection.SortType.connectedOrCreated || sortType === Connection.SortType.updated) {
            headerText = DateExt.dateMediumString(key, navigator.language);
        } else {
            headerText = `${key}`;
        }

        return (
            <div key={key} className={styles.container}>
                <div className={styles.sectionHeader}>{headerText}</div>
                <div className={styles.section}>
                    {this.renderRows(group.value)}
                </div>
            </div>
        );
    }

    renderRows(connections) {
        const { firebase, history } = this.props;

        return connections.map(connection => {
            return (
                <div className={styles.row} key={connection.identifier}>
                    <ConnectionCard connection={connection} showShareButton={true} sortType={this.connectionsListener.sortType} firebase={firebase} history={history} />
                </div>
            )
        })
    }

    render() {
        const { fetchedConnections, filteredConnections, shouldShowFilterDropdown } = this.state;
        const connections = filteredConnections != null ? filteredConnections : fetchedConnections;

        return (
            <div className={`baseContainer baseContainerMargin`}>
                <div className={`${styles.filterIcon} tooltip`} onClick={this.showFilterDropdown}>
                    <span className={`tooltiptext`}>{"Filter & Sort Items"}</span>
                </div>
                {shouldShowFilterDropdown === true && (
                    <div className={`card ${styles.filterDropdown}`}>
                        <div className={styles.filterTitle}>{"Filter & Sort Items"}</div>
                        <div className={styles.filterSeperator} />
                        <div className={`${styles.filterItem} ${this.connectionsListener.sortType === Connection.SortType.firstName ? styles.filterItemSelected : ""}`} onClick={() => {this.filterSelected(Connection.SortType.firstName)}}>First Name</div>
                        <div className={styles.filterSeperator} />
                        <div className={`${styles.filterItem} ${this.connectionsListener.sortType === Connection.SortType.lastName ? styles.filterItemSelected : ""}`} onClick={() => {this.filterSelected(Connection.SortType.lastName)}}>Last Name</div>
                        <div className={styles.filterSeperator} />
                        <div className={`${styles.filterItem} ${this.connectionsListener.sortType === Connection.SortType.rating ? styles.filterItemSelected : ""}`} onClick={() => {this.filterSelected(Connection.SortType.rating)}}>Rating</div>
                        <div className={styles.filterSeperator} />
                        <div className={`${styles.filterItem} ${this.connectionsListener.sortType === Connection.SortType.connectedOrCreated ? styles.filterItemSelected : ""}`} onClick={() => {this.filterSelected(Connection.SortType.connectedOrCreated)}}>Connected or Created</div>
                        {/* <div className={styles.filterSeperator} />
                        <div className={`${styles.filterItem} ${this.connectionsListener._sortType === Connection.SortType.updated ? styles.filterItemSelected : ""}`} onClick={() => {this.filterSelected(Connection.SortType.updated)}}>Updated</div> */}
                    </div>
                )}
                <SearchBar className={styles.searchBar} name="ConnectionSearch" placeholder="Search (use # to search for tags)" onChange={this.connectionSearchDidChange} />
                {
                    connections.map(group => {
                        return this.renderSectionGroup(group);
                    })
                }
                {this.connectionsListener.loadedInitially === true && connections.length === 0 && (
                    <EmptyDataView icon={"empty_connections.svg"} title={"You don't have any connections yet."} />
                )}
                <AddButton className={`addButton`} onClick={this.createPlaceholder} />
            </div>
        )
    }
}

export default Connections
