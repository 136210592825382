import React, { Component } from 'react';
import styles from './CheckmarkButton.module.css';

class CheckmarkButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complete: props.complete
        }
    }

    check = (event) => {
        const { check, identifier } = this.props;
        const { complete } = this.state;
        
        this.setState({
            complete: !complete
        });

        if (check != null) {
            check(identifier, !complete);
        }
    }

    render() {
        const { overdue } = this.props;
        const { complete } = this.state;

        var style = "";
        if (complete === true) {
            style = styles.complete;
        } else if (overdue === true) {
            style = styles.overdue;
        }

        return (
            <div className={`${styles.checkmark} ${style}`} onClick={this.check}></div>
        )
    }
}

export default CheckmarkButton
