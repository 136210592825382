import React, { Component } from 'react';
import styles from './QuickButton.module.css';

class QuickButton extends Component {
    render() {
        const { icon, buttonType, disabled, onClick } = this.props;
        const disabledStyle = disabled === true ? styles.disabled : '';

        return (
            <button className={`${styles.button} ${disabledStyle}`} type={buttonType} onClick={disabled ? null : onClick}>
                <div className={styles.icon} style={{backgroundImage: `url(${icon})`}} />
            </button>
        )
    }
}

export default QuickButton
