import React, { Component } from 'react';
import styles from './NotesCell.module.css';
import DateExt from '../../../Extensions/DateExt';

class NotesCell extends Component {

    rowSelected = (identifier) => {
        const { history, connectionId } = this.props;

        history.push(`/connections/${connectionId}/notes/${identifier}`);
    }

    render() {
        const { note, lastItem } = this.props;

        return (
            <div>
                <div className={`${styles.base} cardHover`} onClick={() => {this.rowSelected(note.identifier)}}>
                    <div className={styles.text}>{note.text}</div>
                    <div className={styles.posted}>{DateExt.timeMediumString(note.posted)}</div>
                </div>
                {lastItem !== true && (
                    <div className={styles.rowSeperator} />
                )}
            </div>
        )
    }
}

export default NotesCell;
