import React, { Component } from 'react';
import { Redirect } from 'react-router';
import styles from './Login.module.css';
import Button from '../../Elements/Button';
import InputDetail from '../../Elements/InputDetail';

class Login extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();

        this.state = {
            isLoggingIn: false,
            redirectOnLogin: false
        };
    }

    componentDidMount() {
        document.title = "Vanir • Login"
    }

    loginUser = (event) => {
        // Stops the page from reloading
        event.preventDefault();

        const { isLoggingIn, username, password } = this.state;

        if (isLoggingIn === true) {
            return;
        }

        this.setState({ isLoggingIn: true});

        this.auth.signInWithEmailAndPassword(username, password)
            .then((credential) => {

                this.setState({
                    isLoggingIn: false,
                    isLoggedIn: true
                });

            })
            .catch(error => {

                if (error != null) {
                    window.alert(error.message);
                }

                this.setState({ isLoggingIn: false });
            });
    }

    usernameChanged = (event) => {
        this.setState({ username: event.target.value})
    }

    passwordChanged = (event) => {
        this.setState({ password: event.target.value})
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/profile" } };
        const { redirectOnLogin, isLoggingIn } = this.state;

        if (redirectOnLogin) {
            return <Redirect to={from} />;
        }
        
        return (
            <div className="card cardMini">
                <h1>Login</h1>
                <form onSubmit={this.loginUser}>
                    <InputDetail title="Email" icon="/img/grey-email.svg" type="email" name="emailaddress" placeholder="Email" autofocus="true" onChange={this.usernameChanged} />
                    <InputDetail title="Password" icon="/img/grey-password.svg" type="password" name="pwd" placeholder="Password" onChange={this.passwordChanged} />
                    <div className={styles.buttonPadding} />
                    <Button title="Login" type="submit" loading={isLoggingIn} />
                </form>
            </div>
        );
    }
}

export default Login;
