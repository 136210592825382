import ImageDownloader from '../../../Managers/ImageDownloader'
import React, { Component } from 'react';
import styles from './CardImageController.module.css';
import CardImage from './CardImage'
import PageControl from '../../Elements/PageControl'
import Card from '../../../Models/Card';

class CardImageController extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.cardImageContainer = React.createRef();

        this.state = {
            cardFront: null,
            cardBack: null,
            pageNumber: 0
        };
    }

    componentDidMount() {
        const { cardImageFilenames } = this.props;

        this.loadCardImage(cardImageFilenames[0], "cardFront")
        this.loadCardImage(cardImageFilenames[1], "cardBack")

        const cardImageContainer = this.cardImageContainer.current;
        if (cardImageContainer != null) {
            cardImageContainer.addEventListener('scroll',this.handleScroll);
        }
    }
    
    componentWillUnmount() {
        const cardImageContainer = this.cardImageContainer.current;
        if (cardImageContainer != null) {
            cardImageContainer.removeEventListener('scroll',this.handleScroll);
        }
    }

    componentDidUpdate() {
        const { cardImageFilenames, editing } = this.props;

        if (editing !== true) {

            if (this.frontImageFile != null) {

                this.frontImageFile = null;
                this.setState({cardFront: null});
                this.loadCardImage(cardImageFilenames[0], "cardFront")
            }

            if (this.backImageFile != null) {

                this.backImageFile = null;
                this.setState({cardBack: null});
                this.loadCardImage(cardImageFilenames[1], "cardBack")
            }
        }
    }

    handleScroll = (event) => {
        const cardImageContainer = this.cardImageContainer.current;
        if (cardImageContainer == null) {
            return;
        }
        
        const contentOffsetX = cardImageContainer.scrollLeft;
        const width = cardImageContainer.offsetWidth;
        const pageNumber = Math.round(contentOffsetX / width);

        this.setState({pageNumber: pageNumber});
    }

    scrollToPage = (pageNumber, animated=true) => {
        const cardImageContainer = this.cardImageContainer.current;
        if (cardImageContainer == null) {
            return;
        }

        const width = cardImageContainer.offsetWidth;
        const xPos = pageNumber * width;

        if (animated === true) {
            this.scrollTo(cardImageContainer, xPos, 250);
        } else {
            cardImageContainer.scrollTo(xPos, 0);
        }
    }

    scrollTo = (element, xPos, duration) => {

        if (duration <= 0) return;
        const difference = xPos - element.scrollLeft;
        const perTick = difference / duration * 10;

        setTimeout(() => {
            element.scrollLeft = element.scrollLeft + perTick;
            if (element.scrollLeft === xPos) return;
            this.scrollTo(element, xPos, duration - 10);
        }, 10);
    }

    loadCardImage = (filename, name) => {

        if (filename == null || filename  === "") {
            return;
        }

        const { connection, userId, cardId } = this.props;
        const currentUser = this.auth.currentUser;

        var path = null;
        if (currentUser != null && connection != null) {
            if (connection.type === "member") {
                path = `users/${connection.identifier}/myCards/${cardId}`;
            } else {
                path = `users/${currentUser.uid}/connections/${connection.identifier}/cards/${cardId}`;
            }
        } else if (userId != null) {
            path = `users/${userId}/myCards/${cardId}`;
        }

        if (path == null) {
            return;
        }

        const ref = this.storage.ref(`${path}/${filename}`);
        ImageDownloader(this.functions, filename, path, name, ref)
            .then(url => {
                this.setState({
                    [name]: url
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    cardImageInputChanged = (side) => {
        const input = document.querySelector(`#cardImage-${side}`);

        if (input == null || input.files.length === 0) {
            return;
        }

        const file = input.files[0];
        const url = window.URL.createObjectURL(file);

        if (side === 'front') {
            this.frontImageFile = file;
            this.setState({cardFront: url});
        } else if (side === 'back') {
            this.backImageFile = file;
            this.setState({cardBack: url});
        }
    }

    deleteCardImage = (side) => {
        const { connection } = this.props;

        if (window.confirm(`Are you sure you want to delete your ${side} card image?`)) { 

            const { card } = this.props;

            var sideIndex = null;
            if (side === 'front') {
                sideIndex = Card.ImageSide.front;
            } else if (side === 'back') {
                sideIndex = Card.ImageSide.back;
            } else {
                return;
            }

            card.deleteCardImage(this.database, this.storage, connection, this.auth.currentUser, sideIndex)
                .then(() => {
                    const input = document.querySelector(`#cardImage-${side}`);
                    input.value = null;

                    if (side === 'front') {
                        this.setState({cardFront: null});
                    } else if (side === 'back') {
                        this.setState({cardBack: null});
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    render() {
        const { editing } = this.props;
        const { pageNumber } = this.state;

        var cardFront = this.state.cardFront
        if (cardFront == null) {
            cardFront = "/img/placeholder-card-business-front.svg"
        }

        var cardBack = this.state.cardBack
        if (cardBack == null) {
            cardBack = "/img/placeholder-card-business-back.svg"
        }

        return (
            <div>
                <div ref={this.cardImageContainer} className={styles.cardImageContainer}>
                    <CardImage side="front" imageUrl={cardFront} editing={editing} cardImageInputChanged={this.cardImageInputChanged} deleteCardImage={this.deleteCardImage} />
                    <CardImage side="back" imageUrl={cardBack} editing={editing} cardImageInputChanged={this.cardImageInputChanged} deleteCardImage={this.deleteCardImage} />
                </div>
                <PageControl numberOfPages={2} currentPageIndex={pageNumber} scrollToPage={this.scrollToPage} />
            </div>
        );
    }
}

export default CardImageController;
