import React, { Component } from 'react';
import styles from './QuickItemView.module.css';
import Detail from '../../Elements/Detail';

class QuickItemView extends Component {

    render() {
        const { items, prefix } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <div className={`baseContainer baseContainerMargin`}>
            {items.favoriteItems.length > 0 && (
                <div className={styles.favourites}>
                    <div className={styles.headerRow}>
                        <div className={styles.header}>Favourites</div>
                    </div>
                    {items.favoriteItems.map(detail => {
                        return <Detail key={detail.value} prefix={prefix} value={detail.value} accessoryIcon={"/img/green-star-active.svg"} />
                    })}
                </div>
            )}
            {items.nonFavoriteItems.length > 0 && (
                <div className={styles.other}>
                    <div className={styles.headerRow}>
                        <div className={styles.header}>Other</div>
                    </div>
                    {items.nonFavoriteItems.map(detail => {
                        return <Detail key={detail.value} value={detail.value} prefix={prefix} />
                    })}
                </div>
            )}
            </div>
        )
    }
}

export default QuickItemView
