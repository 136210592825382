import React, { Component } from 'react';
import styles from './ShareQR.module.css';
import QRCode from 'qrcode-react';
import Connection from '../../../Models/Connection';
import Avatar from '../../Elements/Avatar';
import Member from '../../../Models/Member';

class ShareQR extends Component {
    constructor(props) {
        super(props);

        const firebase = props.firebase;
        this.auth = firebase.auth();
        this.database = firebase.firestore();
        this.storage = firebase.storage();
        this.functions = firebase.functions();

        this.identifier = props.match.params.id;

        this.state = {
            member: null
        };
    }

    componentDidMount() {
        document.title = "Vanir • Share QR";

        if (this.identifier != null) {

            Connection.get(this.database, this.auth.currentUser.uid, this.identifier)
                .then(connection => {

                    if (connection == null) {
                        return;
                    }

                    connection.loadUserImage(this.storage, this.functions, this.auth.currentUser, url => {
                        this.setState({userImageUrl: url});
                    });
        
                    this.setState({
                        member: connection
                    });
                })
                .catch(err => {
                    console.error(err);
                });

        } else {

            Member.get(this.database, this.auth.currentUser.uid, this.connectionId)
                .then(member => {

                    if (member == null) {
                        return;
                    }

                    member.loadUserImage(this.storage, this.functions, null, url => {
                        this.setState({userImageUrl: url});
                    });
        
                    this.setState({
                        member: member
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    render() {
        const { member, userImageUrl } = this.state;

        if (member == null) {
            return <div className={styles.loading}>Loading...</div>
        }
        const shareUrl = `https://vanirapp.com/share?user=${member.identifier}&cards=*`;

        return (
            <div className={`baseContainer baseContainerMargin`}>
                <div className={`card`}>
                    <div className={styles.connectionRow}>
                        <div className={styles.image}>
                            <Avatar imageUrl={member != null ? userImageUrl : null} style={{ width: 48, height: 48, marginTop: 4 }} />
                        </div>
                        <div className={styles.name}>{member != null ? member.name : "Loading..."}</div>
                    </div>
                    <div className={styles.qrCode}>
                        <QRCode value={shareUrl} size={240} includemargin={true} level={'M'} />
                    </div>
                    <div className={styles.detail}>Scan the QR code with Vanir to share this connection</div>
                    <div className={styles.footnote}>By sharing this connection you are giving anyone access to the current information in this profile, including name, user image and card details.</div>
                </div>
            </div>
        )
    }
}

export default ShareQR
