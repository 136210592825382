import Connection from '../../Models/Connection';
import Member from '../../Models/Member';

class ConnectionListener {

    listener = null;
    uid = null;
    connectionId = null;

    constructor(database, storage, connectionId, dataUpdated) {
        
        this.database = database;
        this.storage = storage;
        this.connectionId = connectionId;
        this.dataUpdated = dataUpdated;
    }

    attemptStartAuth(currentUser) {

        if (currentUser == null) {
            return;
        }

        const uid = currentUser.uid;
        this.attemptStart(uid);
    }

    attemptStart(uid) {

        if (this.listener != null) {
            return;
        }

        this.uid = uid;
        const query = this.database.doc(`users/${uid}/connections/${this.connectionId}`);
        this.listener = query.onSnapshot((snapshot) => {

            if (snapshot.exists === true) {
                var connection = null;

                if (snapshot.get("type") === "member") {
                    connection = new Member(snapshot.data(), snapshot.id);
                } else {
                    connection = new Connection(snapshot.data(), snapshot.id);
                }

                if (this.dataUpdated && connection != null) {
                    this.dataUpdated(connection);
                }
            }
        });
    }

    stop() {
        if (this.listener == null) {
            return;
        }

        this.listener();
        this.listener = null;
        this.uid = null;
    }
}

export default ConnectionListener
