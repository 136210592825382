import React, { Component } from 'react';
import styles from './InputDetail.module.css';

class InputDetail extends Component {
    render() {
        const { autofocus, type, name, placeholder, onChange, icon, value, accessoryIcon, onAccessoryClick } = this.props;

        var input;
        if (autofocus === "true") {
            input = <input type={type || 'text'} name={name} placeholder={placeholder} value={value} onChange={onChange} autoFocus />
        } else {
            input = <input type={type || 'text'} name={name} placeholder={placeholder} value={value} onChange={onChange} />
        }

        return (
            <div>
                <div className={styles.row}>
                    <img className={styles.detailIcon} alt="Icon" src={icon}></img>
                    <div className={styles.detail}>
                        {input}
                    </div>
                    {accessoryIcon != null && (
                        <img className={styles.cardDetailAccessoryIcon} alt="Accessory Icon" src={accessoryIcon} onClick={onAccessoryClick} />
                    )}
                </div>
                <div className={styles.rowSeperator}></div>
            </div>
        );
    }
}

export default InputDetail;
