import Card from "../Models/Card";

class CardsListener {

    static Type = Object.freeze({
        connection: "connection",
        profile: "profile"
    });

    listener = null;
    type = null;
    uid = null;
    connectionId = null;
    loadedInitially = false;

    constructor(database, storage, type, connectionId, dataUpdated) {
        
        this.database = database;
        this.storage = storage;
        this.type = type;
        this.connectionId = connectionId;
        this.dataUpdated = dataUpdated;
    }

    attemptStartAuth(currentUser) {

        if (currentUser == null) {
            this.attemptStart(null);
        } else {
            const uid = currentUser.uid;
            this.attemptStart(uid);
        }
    }

    attemptStart(uid) {

        if (this.listener != null || this.type == null) {
            return;
        }

        var path = null;
        if (this.type === CardsListener.Type.connection) {
            path = `users/${uid}/connections/${this.connectionId}/cards`;
        } else if (this.type === CardsListener.Type.profile) {
            path = `users/${this.connectionId}/myCards`;
        } else {
            return;
        }

        const query = this.database.collection(path).orderBy("companyName");
        this.listener = query.onSnapshot((snapshot) => {

            var cards = [];
            snapshot.forEach(doc => {
                if (doc.exists === true) {

                    const card = new Card(doc.data(), doc.id);
                    cards.push(card);
                }
            });

            this.loadedInitially = true;

            if (this.dataUpdated) {
                this.dataUpdated(cards);
            }
        });
    }

    stop() {
        if (this.listener == null) {
            return;
        }

        this.listener();
        this.listener = null;
        this.uid = null;
        this.connectionId = null;
    }

    static numberOfCards(database, type, uid, connectionId) {

        var path = null;
        if (type === CardsListener.Type.connection) {
            path = `users/${uid}/connections/${connectionId}/cards`;
        } else if (type === CardsListener.Type.profile) {
            path = `users/${connectionId}/myCards`;
        } else {
            return Promise.reject(`Couldn't create path for cards!`);
        }

        return database.collection(path).get()
            .then(snap => {
                return snap.size;
            });
    }

}

export default CardsListener
