import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Welcome from '../Pages/Welcome';
import Login from '../Pages/Auth/Login';
import Connections from '../Pages/Connections/Connections';
import Tasks from '../Pages/Tasks/Tasks';
import Share from '../Pages/Share/Share';
import Terms from '../Legal/Terms';
import Privacy from '../Legal/Privacy';
import NotFound from '../Pages/NotFound';
import TaskDetail from '../Pages/Tasks/TaskDetail';
import ConnectionOverview from '../Pages/Connections/ConnectionOverview';
import ComingSoon from '../Pages/ComingSoon';
import ConnectionNotes from '../Pages/Connections/ConnectionNotes';
import ConnectionNote from '../Pages/Connections/ConnectionNote';
import Profile from '../Pages/Profile/Profile';
import ShareQR from '../Pages/Share/ShareQR';

class AppRouter extends Component {
    render() {
        const { isLoggedIn, authFetched } = this.props;

        if (authFetched === false) {
            return null
        }

        return (
            <Switch>
                <Route exact path='/' component={Welcome} />
                <LoginRoute isLoggedIn={isLoggedIn} path='/login' component={(props) => (<Login {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/profile/share' component={(props) => (<ShareQR {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} path='/profile' component={(props) => (<Profile {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/tasks/scheduled/completed' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/tasks/scheduled' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/tasks/list/completed' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/tasks/list' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/tasks' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:connectionId/notes/:id' component={(props) => (<ConnectionNote {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/notes' component={(props) => (<ConnectionNotes {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id/share' component={(props) => (<ShareQR {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/connections/:id' component={(props) => (<ConnectionOverview {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} path='/connections' component={(props) => (<Connections {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/tasks/scheduled/completed' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/tasks/scheduled' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/tasks/list/completed' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/tasks/list' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} exact path='/tasks/:id' component={(props) => (<TaskDetail {...props} firebase={this.props.firebase} />)} />
                <PrivateRoute isLoggedIn={isLoggedIn} path='/tasks' component={(props) => (<Tasks {...props} firebase={this.props.firebase} />)} />

                <PrivateRoute isLoggedIn={isLoggedIn} path='/chat' component={ComingSoon} />

                <Route path='/share' render={(props) => (<Share {...props} firebase={this.props.firebase} />)} />

                <Route path='/terms' component={Terms} />
                <Route path='/privacy' component={Privacy} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default AppRouter

const LoginRoute = ({ component: Component, isLoggedIn, authFetched, ...rest}) => (
    <Route {...rest} render={(props) => (
        isLoggedIn === false
            ? <Component {...props} />
            : <Redirect to={{
                pathname: "/profile",
                state: { from: props.location }
            }} />
    )} />
)

const PrivateRoute = ({ component: Component, isLoggedIn, authFetched, ...rest}) => (
    <Route {...rest} render={(props) => (
        isLoggedIn === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: "/login",
                state: { from: props.location }
            }} />
    )} />
)
