import React, { Component } from 'react';
import styles from './PageControl.module.css';

class PageControl extends Component {
    componentDidMount() {
        window.addEventListener('resize',this.handleResize);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize',this.handleResize);
    }

    handleDotClicked =(event, pageNumber) => {
        event.preventDefault();

        this.props.scrollToPage(event.target.id);
    }

    scrollToCurrentPage = () => {
        const { currentPageIndex } = this.props;
        
        this.props.scrollToPage(currentPageIndex, false);
    }

    handleResize = (event) => {
        this.scrollToCurrentPage();
    }

    previousPage = () => {
        const { currentPageIndex } = this.props;

        this.props.scrollToPage(Math.max(0, currentPageIndex - 1), true);
    }

    nextPage = () => {
        const { numberOfPages, currentPageIndex } = this.props;
        
        this.props.scrollToPage(Math.min(numberOfPages - 1, currentPageIndex + 1), true);
    }

    renderDots() {
        const { numberOfPages, currentPageIndex } = this.props;

        var dots = [];
        for (var index = 0; index < numberOfPages; index++) {
            dots.push(
                <div className={styles.pageDotControl} key={index}>
                    <div className={`${styles.pageDot} ${currentPageIndex === index ? styles.active : ''}`} id={index} onClick={this.handleDotClicked} />
                </div>
            )
        }

        return dots
    }

    render() {
        return (
            <div ref={this.pageControl} className={styles.pageControl}>
                <div className={styles.sideControl} onClick={this.previousPage} />
                {this.renderDots()}
                <div className={styles.sideControl} onClick={this.nextPage} />
            </div>
        );
    }
}

export default PageControl;
